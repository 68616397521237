import React, { useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd5";

import { ExportNoticesButton } from "components/notices/ExportNoticesButton";
import { FilterForm, groupedKeys } from "components/notices/FilterForm";
import { QuickFilterBar } from "components/notices/QuickFilterBar";
import { NoticeFilters } from "components/notices/utils";
import { FrameworkFilterOptions } from "components/record_search/types";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import { FILTER_COLLAPSE_NOTICES, LAYER_CAKE_FILTERS, useVariableValue } from "lib/featureFlags";
import { parseBooleanValue, parseNumberValue, useURLState } from "lib/hooks/useURLState";
import { EventDataTypes } from "lib/tracking";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { ColumnSetting } from "lib/types/models";
import { findKeybyValue } from "../../lib/utils";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import {
  ALL_CALL_OFF_COLUMNS,
  CallOffColumns,
  CallOffTable,
  DEFAULT_CALL_OFF_COLUMNS,
} from "./CallOffTable";
import { DEFAULT_CALL_OFF_FILTERS } from "./utils";

import css from "./FilterableCallOffs.module.scss";

type Props = {
  defaultFilters?: NoticeFilters;
  allAvailableColumns?: ColumnSetting<CallOffColumns>[];
  defaultColumns?: ColumnSetting<CallOffColumns>[];
  frameworkId: string;
};

export function FilterableCallOffs({
  defaultFilters = DEFAULT_CALL_OFF_FILTERS,
  defaultColumns = DEFAULT_CALL_OFF_COLUMNS,
  allAvailableColumns = ALL_CALL_OFF_COLUMNS,
  frameworkId,
}: Props) {
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_NOTICES, false);

  const [activeGroups, setActiveGroups] = useState<string[]>([]);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([...defaultColumns]);
  const [baseFilters = defaultFilters, setFilters] = useURLState<NoticeFilters>(
    "callOffFilters",
    defaultFilters,
    parseCallOffsUrlState,
  );

  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);
  // enforce that frameworkId is always applied to the filters
  //override signals & sector filter if layer cake filters are enabled
  const filters = isLayerCakeFiltersEnabled
    ? {
        ...baseFilters,
        signals: [],
        cpvDimensions: [],
        frameworkIds: [frameworkId],
        frameworkFilterOptions: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
      }
    : {
        ...baseFilters,
        frameworkIds: [frameworkId],
        frameworkFilterOptions: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
      };

  const [callOffSearch, setCallOffSearch] = useState(filters.text);
  const [selectedCallOffs, setSelectedCallOffs] = useState<string[]>([]);
  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((f: NoticeFilters) => {
    setFilters(f);
    setCallOffSearch(f.text);
  });

  return (
    <div className={css.container}>
      <div className={css.actionsContainer}>
        <div className={css.actions}>
          <Input
            placeholder="Search by call-off name"
            prefix={<SearchOutlined />}
            value={callOffSearch || undefined}
            onChange={(e) => {
              setCallOffSearch(e.target.value);
              debouncedSetFilters({ ...filters, text: e.target.value });
            }}
            allowClear
            className={css.searchInput}
          />
          <Button onClick={() => setIsFilterDrawerOpen(true)}>
            <FilterOutlined className={css.filterIcon} />
            Filters
          </Button>
        </div>
        <div className={css.actions}>
          <div className={css.clearSelected}>
            {selectedCallOffs && selectedCallOffs.length > 0 && (
              <ClearSelectedButton
                count={selectedCallOffs.length}
                onClear={() => setSelectedCallOffs([])}
              />
            )}
          </div>
          {selectedCallOffs && selectedCallOffs.length === 0 && (
            <EditColumnsButton
              allAvailableColumns={allAvailableColumns}
              selectedColumns={selectedColumns}
              onNewColumnSettingChange={(c) =>
                setSelectedColumns([...(c as ColumnSetting<CallOffColumns>[])])
              }
              dataType={EventDataTypes.callOff}
            />
          )}
          <ExportNoticesButton filters={filters} selectedNotices={selectedCallOffs} />
        </div>
      </div>
      <QuickFilterBar
        filters={filters}
        onClearFilter={(key) => setFilters({ ...filters, [key]: undefined })}
        onFilterClick={(key) => {
          setIsFilterDrawerOpen(true);
          setActiveGroups([findKeybyValue(key, groupedKeys) || ""]);
        }}
        hiddenSections={["frameworks"]}
      />
      <CallOffTable
        filters={filters}
        onSortChange={(sortState) => setFilters({ ...filters, sort: sortState })}
        selectedColumns={selectedColumns.map((f) => f.field)}
        selectedRows={selectedCallOffs}
        onSelectedRowsChange={(rows) => setSelectedCallOffs(rows)}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onChange={debouncedSetFilters}
            filters={filters}
            options={{ disabledSections: ["frameworks"] }}
            activeGroups={activeGroups}
            onActiveGroupsChange={setActiveGroups}
            showTitle={isCollapseEnabled}
            onClose={() => setIsFilterDrawerOpen(false)}
          />
        )}
      </FilterDrawer>
    </div>
  );
}

function parseCallOffsUrlState(
  state: unknown,
  defaultFilters = DEFAULT_CALL_OFF_FILTERS,
): NoticeFilters {
  if (!isObject(state)) {
    return defaultFilters;
  }

  const filters = state as Record<string, unknown>;

  return {
    value: {
      from: isObject(filters.value) ? parseNumberValue(filters.value?.from) : undefined,
      to: isObject(filters.value) ? parseNumberValue(filters.value?.to) : undefined,
    },
    publishDate: isObject(filters.publishDate) ? filters.publishDate : {},
    procurementStageQualifications: Array.isArray(filters.procurementStageQualifications)
      ? filters.procurementStageQualifications
      : [],
    signals: Array.isArray(filters.signals) ? filters.signals : [],
    assignee: Array.isArray(filters.assignee) ? filters.assignee : [],
    suppliers: Array.isArray(filters.suppliers) ? filters.suppliers.map(parseInt) : [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    supplierSme: parseBooleanValue(filters.supplierSme) as any,
    buyerListIds: Array.isArray(filters.buyerListIds) ? filters.buyerListIds : [],
    buyers: Array.isArray(filters.buyers) ? filters.buyers : [],
    buyerCategories: Array.isArray(filters.buyerCategories) ? filters.buyerCategories : [],
    buyerCountryRegions: {
      countries: isObject(filters.buyerCountryRegions)
        ? (filters.buyerCountryRegions.countries as string[])
        : [],
      regions: isObject(filters.buyerCountryRegions)
        ? (filters.buyerCountryRegions.regions as string[])
        : [],
    },
    text: typeof filters.text === "string" ? filters.text : "",
    stage: Array.isArray(filters.stage) ? filters.stage : [],
    relevanceScore: Array.isArray(filters.relevanceScore) ? filters.relevanceScore : [],
    closeDate: isObject(filters.closeDate) ? filters.closeDate : {},
    expiryDate: isObject(filters.expiryDate) ? filters.expiryDate : {},
    cpvDimensions: Array.isArray(filters.cpvDimensions) ? filters.cpvDimensions : [],
    noticeLists: Array.isArray(filters.noticeLists) ? filters.noticeLists : [],
    frameworkIds: Array.isArray(filters.frameworkIds) ? filters.frameworkIds : [],
    frameworkFilterOptions: Object.values(FrameworkFilterOptions).includes(
      filters.hideFrameworkAgreementType as FrameworkFilterOptions,
    )
      ? (filters.hideFrameworkAgreementType as FrameworkFilterOptions)
      : "",
    supplierMentionType: Object.values(SupplierMentionType).includes(
      filters.supplierMentionType as SupplierMentionType,
    )
      ? (filters.supplierMentionType as SupplierMentionType)
      : undefined,
    keywords: Array.isArray(filters.keywords) ? filters.keywords : [],
    excludeKeywords: Array.isArray(filters.excludeKeywords) ? filters.excludeKeywords : [],
    cpvCodes: Array.isArray(filters.cpvCodes) ? filters.cpvCodes : [],
    sort: {
      field: isObject(filters.sort) ? (filters.sort?.field as string) : "name",
      order: isObject(filters.sort) ? (filters.sort?.order as "ASC" | "DESC") : "DESC",
    },
  };
}

const isObject = (obj: unknown): obj is Record<string, unknown> =>
  obj !== null && typeof obj === "object";
