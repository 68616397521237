import React from "react";

import { useFrameworkSuppliersExport } from "lib/hooks/api/frameworks/useFrameworkSupplierExport";
import { ExportButtonGraphql } from "../actions/ExportButtonGraphql";

export default function ExportFrameworkSuppliersButton({
  frameworkId,
  selectedSupplierGuids,
  totalResults,
  isLoading,
}: {
  frameworkId: string;
  totalResults: number;
  isLoading: boolean;
  selectedSupplierGuids: string[];
}) {
  const frameworkExportMutation = useFrameworkSuppliersExport();

  return (
    <ExportButtonGraphql
      isLoadingTotalResults={isLoading}
      totalResults={selectedSupplierGuids.length > 0 ? selectedSupplierGuids.length : totalResults}
      exportMutation={frameworkExportMutation}
      exportVariables={{
        frameworkId,
        selectedSupplierGuids,
      }}
    />
  );
}
