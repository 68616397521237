import * as React from "react";
import { Empty, Input } from "antd5";

import { ReferenceOrgList } from "components/organisation_clean/OrgListPanel";
import { ReferenceOrgDto } from "lib/generated/app-api";
import { useReferenceOrgs } from "lib/hooks/api/reference_orgs/useReferenceOrgs";

import css from "./OscarSearchWidget.module.scss";

type OscarSearchWidgetProps = {
  onSelect: (b: ReferenceOrgDto) => void;
};

function OscarSearchWidget({ onSelect }: OscarSearchWidgetProps): JSX.Element {
  const [textSearch, setTextSearch] = React.useState<string>("");

  const { data: oscarOrgs } = useReferenceOrgs({ text: textSearch, source: "oscar" });

  return (
    <div className={css.oscarSearchWidget}>
      <header>
        <h1>Oscar search</h1>
        <Input
          placeholder="Search by name..."
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
      </header>
      <div>
        {oscarOrgs?.length ? <ReferenceOrgList orgs={oscarOrgs} onSelect={onSelect} /> : <Empty />}
      </div>
    </div>
  );
}

export default OscarSearchWidget;
