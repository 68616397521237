import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  ExportFrameworkSuppliersMutation,
  ExportFrameworkSuppliersMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const exportFrameworkSuppliers = graphql(`
  mutation exportFrameworkSuppliers(
    $frameworkId: String!
    $exportFormat: ExportFormatArgs!
    $selectedSupplierGuids: [String!]
    $selectedFields: [String!]
  ) {
    exportFrameworkSuppliers(
      frameworkId: $frameworkId
      ExportFormat: $exportFormat
      SelectedFields: $selectedFields
      SelectedSupplierGuids: $selectedSupplierGuids
    ) {
      id
    }
  }
`);

type UseFrameworksExport = UseMutationResult<
  ExportFrameworkSuppliersMutation["exportFrameworkSuppliers"],
  unknown,
  ExportFrameworkSuppliersMutationVariables,
  unknown
>;

export function useFrameworkSuppliersExport(
  options?: UseMutationOptions<
    ExportFrameworkSuppliersMutation,
    unknown,
    ExportFrameworkSuppliersMutationVariables,
    unknown
  >,
): UseFrameworksExport {
  const { data, ...rest } = useGraphQlMutation(exportFrameworkSuppliers, options);
  return { data: data?.exportFrameworkSuppliers, ...rest } as unknown as UseFrameworksExport;
}
