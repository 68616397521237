import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd5";
import classNames from "classnames";

import PdfViewer from "./PdfViewer";

import css from "./PdfViewerContainer.module.scss";

export function PdfViewerContainer({
  documentUrl,
  downloadUrl,
  isLoading,
  containerClassName,
}: {
  documentUrl?: string;
  downloadUrl: string;
  isLoading?: boolean;
  containerClassName?: string;
}) {
  if (isLoading) {
    return <Spin className={css.container} />;
  }

  if (!documentUrl) {
    return (
      <div className={classNames(css.container, containerClassName)}>
        <h3 className={css.infoHeader}>Unsupported document type</h3>
        <div className={css.info}>
          We are unable to display this document type in app. Please download the document to view
          it.
        </div>
        <Button
          download
          type="primary"
          href={downloadUrl}
          icon={<DownloadOutlined />}
          className={css.download}
        >
          Download
        </Button>
      </div>
    );
  }

  return <PdfViewer documentUrl={documentUrl} />;
}
