import * as React from "react";
import { Button, Card, Collapse } from "antd5";
import { CheckboxChangeEvent } from "antd5/es/checkbox";

import { FormState, OnFieldChangeEvent } from "components/account_management/FeedSettings";
import { ResultCountsType } from "components/onboarding/onboardingUtils";
import PaywallModal, { PaywallModalProps } from "components/paywall/PaywallModal";
import { FeatureType, PaidTag } from "components/paywall/paywallUtils";
import { useDialogManager } from "lib/providers/DialogManager";
import { useProHelper } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { Status } from "lib/types/models";
import { CollapseHeader } from "../../../lib/core_components/CollapseHeader";
import { SignalCategory } from "../../../lib/StotlesApi";
import CountriesCollapseContent from "./form_components/Countries";
import CpvCollapseContent from "./form_components/CpvCodes";
import KeywordsCollapseContent from "./form_components/Keywords";
import PartnerCompetitorCollapseContent from "./form_components/PartnersCompetitors";

import css from "./Form.module.scss";

type DefaultDates = {
  keywords: Date;
  suppliers: Date;
};

type PaywallMessageProps = {
  dataType: "SUPPLIERS";
  benefit: string;
};

const DataTypeToFriendlyName = {
  SUPPLIERS: "Supplier",
};

export function PaywallMessage({ dataType, benefit }: PaywallMessageProps): JSX.Element {
  const { checkSubscription } = useProHelper();
  const showHelper = React.useCallback(
    () => checkSubscription(dataType, { "Context source": "button in collapse" }),
    [checkSubscription, dataType],
  );

  return (
    <>
      <p className={css.settingsLabel}>
        This feature is included with the <b>{DataTypeToFriendlyName[dataType]} Intelligence</b>{" "}
        add-on
      </p>
      <p>
        Unlock <b>{DataTypeToFriendlyName[dataType]} Intelligence</b> {benefit}
      </p>
      <Button type="primary" onClick={showHelper}>
        Learn more
      </Button>
    </>
  );
}

interface FeedSettingsFormProps {
  handleFieldChange: (change: OnFieldChangeEvent) => void;
  formState: FormState;
  defaultDates: DefaultDates;
  resultCounts?: ResultCountsType;
  onValidate: (isFormValid: boolean) => void;
  useSupplierName: boolean;
  internationalDataAccess: boolean;
  feedPreviewLoading: boolean;
}

function FeedSettingsForm({
  handleFieldChange,
  formState,
  defaultDates,
  resultCounts,
  onValidate,
  useSupplierName,
  internationalDataAccess,
  feedPreviewLoading,
}: FeedSettingsFormProps): JSX.Element {
  const subscription = useSubscription();
  const canSetSuppliers = subscription.hasDataTypes("SUPPLIERS");
  const dialogManager = useDialogManager();

  const openPaywallModal = React.useCallback(
    (featureType: FeatureType) => {
      void dialogManager.openDialog(PaywallModal, {
        featureType: featureType,
      } as Omit<PaywallModalProps, "isOpen" | "onClose">);
    },
    [dialogManager],
  );

  const [activeKeys, setActiveKeys] = React.useState<string | string[]>(() => {
    const keys = ["keywords", "countries", "cpv_codes"];
    if (canSetSuppliers) keys.push("competitors", "partners");
    return keys;
  });

  const countriesValid = React.useMemo(() => {
    return formState.countries.length > 0;
  }, [formState.countries]);

  const keywordsValid = React.useMemo(() => {
    return formState.keywords.length > 0;
  }, [formState.keywords]);

  React.useEffect(() => {
    onValidate(keywordsValid && countriesValid);
  }, [onValidate, keywordsValid, countriesValid, formState]);

  // simple callbacks which require just the one dependency (others can live in separate hooks)
  const handleFieldChanges = React.useMemo(() => {
    return {
      keywords: (newVals: string[] | undefined) =>
        handleFieldChange({ keywords: newVals?.length ? newVals : [] }),
      keywordsDate: (newDate: Date) => handleFieldChange({ keywordsFromDate: newDate }),
      keywordsExclude: (newWords: string[]) => handleFieldChange({ excludeKeywords: newWords }),
      keywordsCpvCodes: (newCodes: string[]) =>
        handleFieldChange({ keywordsCpvCodes: newCodes.length ? newCodes : undefined }),
      keywordsCpvCodesIncludeNull: (e: CheckboxChangeEvent) =>
        handleFieldChange({ keywordsCpvCodesIncludeNull: e.target.checked }),
      keywordsStatus: (newStatuses: Status[]) =>
        handleFieldChange({ status: newStatuses.length ? newStatuses : [] }),
      countries: (newCountries: string[] | undefined) =>
        handleFieldChange({ countries: newCountries?.length ? newCountries : [] }),
      languages: (newLanguages: string[] | undefined) =>
        handleFieldChange({
          languages:
            newLanguages === undefined ? undefined : newLanguages?.length ? newLanguages : [],
        }),
      competitorDate: (newDate: Date) => handleFieldChange({ competitorsFromDate: newDate }),
      partnerDate: (newDate: Date) => handleFieldChange({ partnersFromDate: newDate }),
    };
  }, [handleFieldChange]);

  const handleSupplierChanges = React.useMemo(() => {
    function createHandleSupplierChange<T extends string[] | number[]>(
      valuesField:
        | "partners"
        | "competitors"
        | "partnerNames"
        | "competitorNames"
        | "competitorOrgIds"
        | "partnerOrgIds",
      dateField: "partnersFromDate" | "competitorsFromDate",
    ) {
      // obviously this could be read dynamically, but for linting it is easier to see what the
      // dependencies when it is like this
      const selectedDate =
        dateField === "partnersFromDate"
          ? formState.partnersFromDate
          : formState.competitorsFromDate;

      return (newValues: T) => {
        if (newValues.length === 0) {
          handleFieldChange({
            [valuesField]: [],
            [dateField]: undefined,
          });
        }
        if (selectedDate) {
          handleFieldChange({ [valuesField]: newValues });
        } else {
          handleFieldChange({
            [valuesField]: newValues,
            [dateField]: defaultDates.suppliers,
          });
        }
      };
    }

    return {
      competitorIds: createHandleSupplierChange("competitors", "competitorsFromDate"),
      competitorNames: createHandleSupplierChange("competitorNames", "competitorsFromDate"),
      competitorOrgIds: createHandleSupplierChange("competitorOrgIds", "competitorsFromDate"),
      partnerIds: createHandleSupplierChange("partners", "partnersFromDate"),
      partnerNames: createHandleSupplierChange("partnerNames", "partnersFromDate"),
      partnerOrgIds: createHandleSupplierChange("partnerOrgIds", "partnersFromDate"),
    };
  }, [
    handleFieldChange,
    defaultDates.suppliers,
    formState.partnersFromDate,
    formState.competitorsFromDate,
  ]);

  return (
    <form className={css.feedSettingsInputs}>
      {/* Many antd styles depend on inputs being in a form element */}

      <Collapse
        activeKey={activeKeys}
        onChange={setActiveKeys}
        items={[
          {
            key: "countries",
            showArrow: false,
            label: <CollapseHeader itemKey="countries" title="Countries" activeKeys={activeKeys} />,
            children: (
              <CountriesCollapseContent
                valid={countriesValid}
                values={formState.countries}
                onValuesChange={handleFieldChanges.countries}
                internationalDataAccess={internationalDataAccess}
                languages={formState.languages}
                onLanguagesChange={handleFieldChanges.languages}
              />
            ),
          },
        ]}
      />
      <Collapse
        activeKey={activeKeys}
        onChange={setActiveKeys}
        items={[
          {
            key: "keywords",
            showArrow: false,
            label: <CollapseHeader itemKey="keywords" title="Keywords" activeKeys={activeKeys} />,
            children: (
              <KeywordsCollapseContent
                valid={keywordsValid}
                values={formState.keywords}
                onValuesChange={handleFieldChanges.keywords}
                cpvCodes={formState.keywordsCpvCodes}
                cpvCodesIncludeNull={formState.keywordsCpvCodesIncludeNull}
                exclude={formState.excludeKeywords}
                keywordsCounts={resultCounts && resultCounts[SignalCategory.KEYWORD]}
                onCpvCodesIncludeNullChange={handleFieldChanges.keywordsCpvCodesIncludeNull}
                onExcludeChange={handleFieldChanges.keywordsExclude}
                onCpvCodesChange={handleFieldChanges.keywordsCpvCodes}
                feedPreviewLoading={feedPreviewLoading}
              />
            ),
          },
        ]}
      />
      <Collapse
        activeKey={activeKeys}
        onChange={setActiveKeys}
        items={[
          {
            key: "cpvCodes",
            showArrow: false,
            label: <CollapseHeader itemKey="cpvCodes" title="CPV Codes" activeKeys={activeKeys} />,
            children: (
              <CpvCollapseContent
                values={formState.cpvCodes}
                onValuesChange={(newVals) =>
                  handleFieldChange({ cpvCodes: newVals?.length ? newVals : undefined })
                }
              />
            ),
          },
        ]}
      />
      {!canSetSuppliers ? (
        <>
          <Card>
            <div className={css.upgradeContent}>
              <PaidTag />
              <h1 className={css.title}>Upgrade to unlock competitor intelligence</h1>
              <p className={css.upgradeTxt}>
                Map your competitors to identify contracts they've won and upcoming opportunities
                you can infiltrate.
              </p>
              <Button
                type="primary"
                onClick={() => openPaywallModal("COMPETITORS")}
                className={css.learnMoreBtn}
              >
                Learn more
              </Button>
            </div>
          </Card>

          <Card>
            <div className={css.upgradeContent}>
              <PaidTag />
              <h1 className={css.title}>Upgrade to unlock partner intelligence</h1>
              <p className={css.upgradeTxt}>
                Map your partner network to identify contracts they've won and upcoming
                opportunities you can pursue.
              </p>
              <Button
                type="primary"
                onClick={() => openPaywallModal("PARTNERS")}
                className={css.learnMoreBtn}
              >
                Learn more
              </Button>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Collapse
            activeKey={activeKeys}
            onChange={setActiveKeys}
            items={[
              {
                key: "competitors",
                showArrow: false,
                label: (
                  <CollapseHeader
                    itemKey="competitors"
                    title="Competitors"
                    activeKeys={activeKeys}
                  />
                ),
                children: (
                  <PartnerCompetitorCollapseContent
                    active={canSetSuppliers}
                    useSupplierName={useSupplierName}
                    onSupplierIdsChange={handleSupplierChanges.competitorIds}
                    onSupplierGuidsChange={handleSupplierChanges.competitorOrgIds}
                    onSupplierNamesChange={handleSupplierChanges.competitorNames}
                    supplierIds={formState.competitors || []}
                    supplierGuids={formState.competitorOrgIds || []}
                    supplierNames={formState.competitorNames || []}
                    supplierCounts={resultCounts && resultCounts[SignalCategory.COMPETITOR]}
                    supplierType="competitor"
                  />
                ),
              },
            ]}
          />

          <Collapse
            activeKey={activeKeys}
            onChange={setActiveKeys}
            items={[
              {
                key: "partners",
                showArrow: false,
                label: (
                  <CollapseHeader itemKey="partners" title="Partners" activeKeys={activeKeys} />
                ),
                children: (
                  <PartnerCompetitorCollapseContent
                    active={canSetSuppliers}
                    useSupplierName={useSupplierName}
                    onSupplierIdsChange={handleSupplierChanges.partnerIds}
                    onSupplierGuidsChange={handleSupplierChanges.partnerOrgIds}
                    onSupplierNamesChange={handleSupplierChanges.partnerNames}
                    supplierGuids={formState.partnerOrgIds || []}
                    supplierIds={formState.partners || []}
                    supplierNames={formState.partnerNames || []}
                    supplierCounts={resultCounts && resultCounts[SignalCategory.PARTNER]}
                    supplierType="partner"
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </form>
  );
}

export default FeedSettingsForm;
