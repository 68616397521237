import React, { useMemo } from "react";

import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { DEFAULT_NOTICE_SORT_ORDER } from "components/my_feed/useMyFeedPageState";
import { NEW_NOTICES_SEARCH, NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { useNotices } from "lib/hooks/api/notices/useNotices";
import { useRecordSearch } from "lib/hooks/api/useRecordSearch";
import { EventDataTypes } from "lib/tracking";
import {
  cpvCodeFilterToLabel,
  keywordsFilterToLabel,
  supplierGuidsFilterToLabel,
  supplierMentionTypeToLabel,
} from "lib/utils/filterToLabelUtils";
import { DEFAULT_PAGINATION } from "./NoticeTable";
import {
  convertNoticeFiltersToApi,
  convertNoticeFiltersToSearchRequest,
  FilterSection,
  NoticeFilters,
} from "./utils";

function getAppliedFilters(filters: NoticeFilters) {
  const appliedFilters = Object.entries(filters).filter(([key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (key === "buyerCountryRegions") {
      const val = value as {
        countries: string[];
        regions: string[];
      };

      if (val.countries?.length > 0 || val.regions?.length > 0) {
        return true;
      }

      return false;
    }
    if (typeof value === "object") {
      if ("hideNulls" in value && "filter" in value) {
        return Object.values(value.filter || {}).filter((v) => v).length > 0 || value.hideNulls;
      }
      if ("hideNulls" in value || "to" in value || "from" in value) {
        return (
          Object.values(value).filter((v) => {
            if (typeof v === "number") {
              return true;
            }
            return v;
          }).length > 0
        );
      }
      if (key === "sort") {
        return false;
      }
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string") {
      return value.length > 0;
    }
    if (typeof value === "boolean") {
      return true;
    }
    return false;
  });

  return appliedFilters.map(([key]) => key);
}

const sectionToFilterKey: Record<FilterSection, (keyof NoticeFilters)[]> = {
  suppliers: ["supplierGuids", "supplierSme", "suppliers", "supplierMentionType"],
  buyers: ["buyerListIds", "buyers", "buyerCategories", "buyerCountryRegions"],
  frameworks: ["frameworkIds", "frameworkFilterOptions"],
};

const NO_LABEL_FIELDS: (keyof NoticeFilters)[] = ["sort", "buyerIds"];

type Props = {
  filters: NoticeFilters;
  onClearFilter: (value: keyof NoticeFilters) => void;
  hiddenSections?: FilterSection[];
  hiddenFields?: (keyof NoticeFilters)[];
  onFilterClick: (key: keyof NoticeFilters) => void;
};
export function QuickFilterBar({
  filters,
  onClearFilter,
  hiddenFields = [],
  hiddenSections,
  onFilterClick,
}: Props) {
  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const recordFilters = convertNoticeFiltersToApi(filters, DEFAULT_PAGINATION);
  const { isLoading: isLoadingRecords, data: recordData } = useRecordSearch(recordFilters, {
    enabled: !useOpenSearch,
  });

  const { isLoading: isLoadingNotices, data: noticeData } = useNotices(
    convertNoticeFiltersToSearchRequest(filters, DEFAULT_PAGINATION),
    false,
    {
      enabled: useOpenSearch,
    },
  );

  const isLoading = useOpenSearch ? isLoadingNotices : isLoadingRecords;
  const totalResults = useOpenSearch
    ? noticeData?.pagingInfo?.totalResults
    : recordData?.pagingInfo?.totalResults;
  const appliedFilters = getAppliedFilters(filters);

  const hideFields: (keyof NoticeFilters)[] = useMemo(() => {
    const hiddenSectionFields =
      hiddenSections?.map((section) => sectionToFilterKey[section]).flat() || [];
    const combinedHiddenFields = hiddenSectionFields.concat(hiddenFields).concat(NO_LABEL_FIELDS);

    if (supplierMentionTypeToLabel(filters.supplierMentionType) === "") {
      combinedHiddenFields.push("supplierMentionType");
    }

    if (enableNewSupplierFilter && window.currentUser?.use_supplier_name === false) {
      // exclude the supplier filter chip if we're using supplierGuids
      // for the new supplier filter
      combinedHiddenFields.push("suppliers");
    }

    return combinedHiddenFields;
  }, [hiddenFields, filters.supplierMentionType, hiddenSections, enableNewSupplierFilter]);

  const filterKeyToLabel: Record<keyof NoticeFilters, string> = {
    procurementStageQualifications: "Qualification",
    signals: "Signals",
    assignee: "Assignee",
    buyerListIds: "Buyer lists",
    buyers: `Buyers (${filters.buyers.length})`,
    buyerCategories: "Buyer type",
    value: "Value",
    text: "Keywords",
    stage: "Stage",
    closeDate: "Close date",
    expiryDate: "Expiry date",
    relevanceScore: "Signal score",
    publishDate: "Publish date",
    suppliers: filters.supplierGuids?.length ? "" : `Suppliers (${filters.suppliers?.length})`,
    supplierGuids: supplierGuidsFilterToLabel(filters.supplierGuids),
    supplierSme: "Supplier SME",
    cpvDimensions: "Sector",
    buyerCountryRegions: "Buyer location",
    noticeLists: "Notice lists",
    sort: "",
    buyerIds: "",
    frameworkIds: "Frameworks",
    frameworkFilterOptions: "Framework activity",
    supplierMentionType: supplierMentionTypeToLabel(filters.supplierMentionType),
    keywords: keywordsFilterToLabel(filters.keywords),
    excludeKeywords: `Exclude keywords (${filters.excludeKeywords.length})`,
    cpvCodes: cpvCodeFilterToLabel(filters.cpvCodes),
  };

  return (
    <FilterBar<NoticeFilters>
      appliedFilters={appliedFilters}
      filterKeyToLabel={filterKeyToLabel}
      sortField={filters.sort?.field || DEFAULT_NOTICE_SORT_ORDER.field}
      onClear={onClearFilter}
      isLoading={isLoading}
      totalResults={totalResults || 0}
      hiddenFields={[...hideFields, "text"]}
      dataType={EventDataTypes.record}
      onFilterClick={onFilterClick}
    />
  );
}
