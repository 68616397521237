import { isEqual, pickBy } from "lodash";

import { SignalSettings } from "lib/hooks/api/teams/useSignalSettingsGQL";
import {
  EventData,
  EventDataTypes,
  EventNames,
  Tracking,
  TrackingEvent,
  useTracking,
} from "lib/tracking";
import { isEmptyDeep } from "lib/utils";
import { handleSavedSignalTrackingData } from "lib/utils/signalUtils";
import { filterKeyToLabel } from "./QuickFilterBar";
import { FrameworkFilters } from "./utils";

const trackingEvents = (tracking: Tracking) => ({
  filterDrawerOpened: () =>
    tracking.logEvent(EventNames.filterPanelOpened, {
      "Context source": "Filters button",
      "Data type": EventDataTypes.frameworks,
    }),
  freeSearchLog: () =>
    tracking.logEvent(EventNames.freeSearch, {
      "Filter name": "Free search",
      "Action type": "Filter actioned",
      "Data type": EventDataTypes.frameworks,
    }),
  filterFormChanged: (
    previous: FrameworkFilters,
    current: FrameworkFilters,
    signalSettings: SignalSettings | undefined,
  ) => {
    const diff = pickBy(current, (value, key) => {
      const previousVal = previous[key as keyof FrameworkFilters];

      // If this check isn't present, the tracking code is triggered for empty filters also
      if (isEmptyDeep(previousVal) && isEmptyDeep(value)) return false;
      return !isEqual(previous[key as keyof FrameworkFilters], value);
    });

    const events: TrackingEvent[] = Object.entries(diff).map(([key, value]) => {
      let data: EventData = {
        "Context source": "Filter slide-out",
        "Filter name": filterKeyToLabel()[key as keyof FrameworkFilters],
        "Filter selection": value,
        "Action type":
          Array.isArray(value) && value.length === 0 ? "Filter cleared" : "Filter actioned",
        "Data type": EventDataTypes.frameworks,
      };

      if (Array.isArray(value) && signalSettings) {
        data = handleSavedSignalTrackingData(data, key, value as string[], signalSettings);
      }

      return { name: EventNames.filterActioned, data };
    });

    tracking.logEvents(...events);
  },
});

export function useFrameworksTracking() {
  const tracking = useTracking();
  return trackingEvents(tracking);
}
