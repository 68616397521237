import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { HubspotIdTokenQuery } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const hubspotIdTokenQuery = graphql(`
  query hubspotIdToken {
    hubspotIdToken {
      token
    }
  }
`);

export function useHubspotIdToken(
  options?: UseQueryOptions<HubspotIdTokenQuery, unknown, HubspotIdTokenQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["hubspotIdToken"], hubspotIdTokenQuery, [], {
    ...options,
    staleTime: 1000 * 60 * 60 * 12, // 12 hours
  });
  return { data: data?.hubspotIdToken, ...rest };
}
