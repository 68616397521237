import React from "react";
import { Control, Controller } from "react-hook-form";
import { InputNumber } from "antd5";

import { Checkbox } from "../../form_components/Inputs";
import { FrameworkFilters } from "../utils";

import css from "./ContractValue.module.scss";

export interface ContractValueFilterContentProps {
  control: Control<FrameworkFilters, unknown>;
  data: FrameworkFilters;
}

function ContractValueFilterContent({ control, data }: ContractValueFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Contract value filters">
      <div className={css.inputContainer}>
        <div className={css.valueFields}>
          <Controller
            name="value.from"
            control={control}
            rules={{ min: 0 }}
            render={({ field, fieldState }) => (
              <InputNumber
                {...field}
                status={fieldState.error && "error"}
                min={0}
                placeholder="From"
                className={css.valueInput}
              />
            )}
          />
          -
          <Controller
            name="value.to"
            control={control}
            rules={{ min: data.value?.from || 0 }}
            render={({ field, fieldState }) => (
              <InputNumber
                {...field}
                status={fieldState.error && "error"}
                min={0}
                placeholder="To"
                className={css.valueInput}
              />
            )}
          />
        </div>
        <Checkbox
          label=""
          name="value.hideNulls"
          control={control}
          fieldLabel="Hide frameworks that don't have a value"
        />
      </div>
    </div>
  );
}

export default ContractValueFilterContent;
