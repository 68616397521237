import React from "react";
import { Button } from "antd5";

import css from "./EmptyDocuments.module.scss";

import EmptyDocumentsSvg from "../../../assets/images/empty_box.svg";

type Props = {
  setIsFilterDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function EmptyDocuments({ setIsFilterDrawerOpen }: Props) {
  return (
    <div className={css.container}>
      <img src={EmptyDocumentsSvg} alt="box" />
      <h3>Nothing here, but don't give up!</h3>
      <p>
        Try a new search or filters - the document you're looking for could be just around the
        corner.
      </p>
      <Button type="primary" onClick={() => setIsFilterDrawerOpen(true)} className={css.actionBtn}>
        Open filters
      </Button>
    </div>
  );
}
