import React, { Dispatch, useCallback, useMemo, useRef, useState } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import {
  BarController,
  BarElement,
  Chart,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { DateTime } from "luxon";

import "chartjs-adapter-luxon";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import { LegendClickHandler } from "lib/chartjs/plugins/LegendClickHandler";
import { StotlesChartStyle } from "lib/chartjs/plugins/StotlesChartStyle";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { useTransactionSummaryChart } from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  SpendDataFilters,
  TransactionSummaryChartData,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useDialogManager } from "lib/providers/DialogManager";
import { EventNames, TrackingEvent, TrackingProvider, useTracking } from "lib/tracking";
import { formatShortAmount } from "lib/utils";
import { DateUnitSelector } from "./DateUnitSelector";
import { NumberDataPointValue, SpendTooltip } from "./SpendTooltip";
import { TransactionsModal } from "./TransactionsModal";

import css from "./TransactionChart.module.scss";

type Props = {
  filters: SpendDataFilters;
  dateUnit: "month" | "quarter" | "year";
  setDateUnit: Dispatch<"month" | "quarter" | "year">;
};

Chart.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  BarController,
  Tooltip,
  Legend,
  LegendClickHandler,
  StotlesChartStyle,
);

function createChartClickedOnEvent(filterValue: string): TrackingEvent {
  return {
    name: EventNames.chartClickedOn,
    data: {
      "Action type": "Data Inspected",
      "Context source": "All Transactions Chart",
      "Filter value": filterValue,
    },
  };
}

export function TransactionChart({ filters, dateUnit, setDateUnit }: Props) {
  const [tooltipProps, setTooltipProps] = useState<{
    top: number;
    left: number;
    show: boolean;
    dataPoints?: TooltipItem<"line">[];
  }>({
    top: 0,
    left: 0,
    show: false,
  });

  const { data, isLoading } = useTransactionSummaryChart({
    filters,
    dateUnit,
  });

  const dialogManager = useDialogManager();
  const { logEvents } = useTracking();

  const chartRef = useRef<ChartJSOrUndefined<"bar">>(undefined);

  const options: ChartOptions<"line" | "bar"> = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          useBorderRadius: true,
          borderRadius: 4,
          boxWidth: 12,
        },
      },
      tooltip: {
        mode: "index",
        enabled: false,
        external: (context) => {
          if (
            tooltipProps.show === !!context.tooltip.opacity &&
            tooltipProps.top === context.tooltip.caretY / 2 + 25 &&
            tooltipProps.left === context.tooltip.caretX + 25
          ) {
            return;
          }

          setTooltipProps((o) => ({
            ...o,
            top: context.tooltip.caretY / 2 + 25,
            left: context.tooltip.caretX + 25,
            show: !!context.tooltip.opacity,
            dataPoints: context.tooltip.dataPoints,
          }));
        },
      },
    },
    hover: {
      mode: "nearest",
    },
    onHover: (event, activeElements) => {
      if (event.native?.target)
        (event.native.target as HTMLElement).style.cursor = activeElements?.length
          ? "pointer"
          : "default";
    },
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 5,
        hitRadius: 25,
      },
      line: {
        tension: 0,
      },
    },
    interaction: {
      mode: "x",
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          unit: dateUnit,
          displayFormats: {
            quarter: "Qq yyyy",
            month: "MMM yyyy",
            year: "yyyy",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          align: "center",
        },
      },
      y: {
        title: {
          display: true,
          text: "Value GBP",
        },
        ticks: {
          callback: (value: string | number) => formatShortAmount(value),
        },
      },
      y1: {
        title: {
          display: true,
          text: "Transactions",
        },
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const onClick = useCallback(
    (evt) => {
      const chart = chartRef.current;
      if (!chart) {
        return;
      }
      const dataset = getElementAtEvent(chart, evt);
      if (dataset.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedDate = DateTime.fromMillis((dataset[0] as any).element.$context.parsed.x);

        const dateFrom = selectedDate.startOf(dateUnit).toISODate() ?? undefined;
        const dateTo = selectedDate.endOf(dateUnit).toISODate() ?? undefined;

        logEvents(createChartClickedOnEvent(selectedDate.toISODate() ?? ""));

        void dialogManager.openDialog(TransactionsModal, {
          filters: {
            ...filters,
            dateFrom,
            dateTo,
          },
        });
      }
    },
    [dateUnit, dialogManager, filters, logEvents],
  );

  const dates = useMemo(() => {
    const mindate = data?.data[0]?.date;
    const maxdate = data?.data[data?.data.length - 1]?.date;
    const dates: string[] = [];

    if (mindate && maxdate) {
      for (
        let d = DateTime.fromISO(mindate);
        d <= DateTime.fromISO(maxdate);
        d = d.plus({ [dateUnit]: 1 })
      ) {
        dates.push(d.toString());
      }
    }
    return dates;
  }, [data?.data, dateUnit]);

  const chartData = useMemo(() => {
    const dateMap = new Map<string, TransactionSummaryChartData>();
    data?.data.forEach((d) => {
      dateMap.set(DateTime.fromISO(d.date).toFormat("yyyy-MM-dd"), d);
    });

    return dates.map(
      (d) =>
        dateMap.get(DateTime.fromISO(d).toFormat("yyyy-MM-dd")) ?? {
          date: d,
          total: 0,
          totalRecords: 0,
        },
    );
  }, [data?.data, dates]);

  const chartjsData = useMemo(
    () => ({
      labels: dates,
      datasets: [
        {
          label: "Total Spend",
          data: chartData.map((d) => d.total) ?? [],

          color: "red",
          borderColor: "#4F86FA",
          type: "line" as "bar",
          pointRadius: 0,
          yAxisID: "y",
          pointHoverRadius: 5,
        },
        {
          label: "Transactions",
          data: chartData.map((d) => d.totalRecords) ?? [],
          backgroundColor: "#FFD86C",
          fill: false,
          type: "bar" as const,
          yAxisID: "y1",
        },
      ],
    }),
    [chartData, dates],
  );

  const formatDataPointValue = useCallback(
    (dataPoint: TooltipItem<"line" | "bar">) =>
      dataPoint.dataset.label === "Transactions" ? (
        <NumberDataPointValue dataPoint={dataPoint} />
      ) : (
        <NumberDataPointValue dataPoint={dataPoint} currency="GBP" />
      ),
    [],
  );

  const formatDataPointLabel = useCallback(
    (dataPoint: TooltipItem<"line" | "bar">) => {
      if (options.scales?.x?.type !== "time" || !options.scales?.x?.time?.unit) {
        return dataPoint.label;
      }

      const format =
        options.scales.x?.time?.displayFormats?.[options.scales?.x?.time?.unit] ?? "yyyy-MM-dd";
      return DateTime.fromMillis(dataPoint.parsed.x).toFormat(format);
    },

    [options.scales?.x],
  );

  return (
    <TrackingProvider data={{ "Context source": "All Transactions" }}>
      <DetailsSection
        title="Total spend over time"
        action={<DateUnitSelector dateUnit={dateUnit} setDateUnit={setDateUnit} />}
      >
        <div className={css.chartContainer}>
          {isLoading ? (
            <CentredSpinner />
          ) : (
            <>
              <Bar ref={chartRef} data={chartjsData} options={options} onClick={onClick} />
              <SpendTooltip
                {...tooltipProps}
                formatDataPointValue={formatDataPointValue}
                formatDataPointLabel={formatDataPointLabel}
                clickMessage="Click to inspect"
              />
            </>
          )}
        </div>
      </DetailsSection>
    </TrackingProvider>
  );
}
