import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { UseMutationResult } from "@tanstack/react-query";
import { App, Button, Dropdown, Tooltip } from "antd5";
import { ItemType } from "antd5/es/menu/interface";

import { blue400 } from "lib/themes/colors";
import { downloadFileFromLink } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";
import { ExportFormatArgs } from "../../lib/generated/app-service-gql/graphql";
import { useFileExportStatus } from "../../lib/hooks/api/exports/useFileExportStatus";
import { useRestrictedGuestAccess } from "../../lib/hooks/useRestrictedRowClick";
import { EventNames, getPageSource, useTracking } from "../../lib/tracking";

import css from "./ExportButtonGraphql.module.scss";

const DEFAULT_EXPORT_LMIT = 500;

type Format = "xlsx" | "csv";
type RequiredExportVariables = { exportFormat: ExportFormatArgs };
type RequiredDataResult = { id: string };
type Props<T extends RequiredDataResult, V extends RequiredExportVariables> = {
  exportMutation: UseMutationResult<T, unknown, V, unknown>;
  exportVariables: Omit<V, "exportFormat">;
  totalResults: number;
  isLoadingTotalResults: boolean;
  exportLimit?: number;
};
// TODO: rename once this is the primary export button
export function ExportButtonGraphql<
  T extends RequiredDataResult,
  V extends RequiredExportVariables,
>({
  isLoadingTotalResults,
  totalResults,
  exportMutation,
  exportVariables,
  exportLimit = DEFAULT_EXPORT_LMIT,
}: Props<T, V>) {
  const { message } = App.useApp();
  const { openSupportChat } = useSupportChat();
  const { mutate, isLoading: isLoadingNoticeExport, data, variables, reset } = exportMutation;
  const { trackingData, logEvent } = useTracking();
  const { data: exportStatus } = useFileExportStatus({
    exportId: data?.id,
    onSuccess(downloadUrl) {
      logEvent(EventNames.exportedData, {
        Count: totalResults,
        Format: variables?.exportFormat?.fileFormat,
      });
      downloadFileFromLink(
        downloadUrl,
        `stotles-notices-export-${new Date().toJSON().slice(0, 10)}.${variables?.exportFormat?.fileFormat}`,
      );
      reset();
    },
    onError() {
      void message.error("Export failed. Please try again later.");
      reset();
    },
  });

  // isExporting is true if the export mutation is loading or we have an export id and exportStatus is not complete or errored
  const isExporting =
    isLoadingNoticeExport ||
    !!(data?.id && !exportStatus?.fileExport.isComplete && !exportStatus?.fileExport.isError);

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(trackingData);

  const startExport = async (format: Format) => {
    if (window.guestUser) {
      return openGuestAccessPassPaywall();
    }
    mutate({ ...exportVariables, exportFormat: { fileFormat: format } } as V);
  };

  const menuItens: ItemType[] = [
    {
      key: "xlsx",
      label: <span onClick={() => startExport("xlsx")}>Export to XLSX</span>,
    },
    {
      key: "csv",
      label: <span onClick={() => startExport("csv")}>Export to CSV</span>,
    },
  ];

  // TODO extract this to separate PaywallExport wrapper component or similar
  const exportLimitReached = totalResults > exportLimit;
  const hasExportPermission = window.currentUser?.has_export_permission;

  const limitExport = exportLimitReached && !hasExportPermission;

  return (
    <Dropdown menu={{ items: menuItens }} disabled={isExporting}>
      <Tooltip
        title={
          limitExport ? (
            <span>
              {`Narrow results to ${exportLimit} or less to export. Need more? `}
              <br />
              <Button
                type="text"
                onClick={() => openSupportChat(getPageSource())}
                style={{ color: blue400, padding: 0 }}
              >
                Chat with our team
              </Button>{" "}
              to get started.
            </span>
          ) : (
            ""
          )
        }
      >
        <Button
          loading={isLoadingTotalResults || isExporting}
          disabled={isLoadingTotalResults || isExporting || limitExport}
          icon={<DownloadOutlined className={css.downloadIcon} />}
        >
          Export ({totalResults})
        </Button>
      </Tooltip>
    </Dropdown>
  );
}
