import * as React from "react";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Tooltip } from "antd5";

import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import PaywallStar from "lib/icons/PaywallStar";
import { ProHelperDataTypes, useCheckSubscription } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { EventDataTypes, EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { downloadFile } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";
import { TextLink } from "./Links";
import TextButton from "./TextButton";

import css from "./ExportButton.module.scss";

type Props = {
  exportAction:
    | Record<"xlsx" | "csv", string>
    | ((format: "xlsx" | "csv") => Promise<[string, Blob]>);
  small?: boolean;
  eventName?: EventNames;
  eventAttributes?: Record<string, string | number>;
  label?: string;
  contextType?: "dropdown" | "submenu";
  requiredDataType?: ProHelperDataTypes;
  className?: string;
  disabled?: boolean;
  disabledReason?: JSX.Element;
  loading?: boolean;
};

function ExportButton({
  exportAction,
  label,
  small,
  eventName,
  eventAttributes,
  requiredDataType,
  disabled,
  disabledReason,
  className,
  loading,
}: Props): JSX.Element {
  const { logEvent, trackingData } = useTracking();
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(trackingData);

  const { authorised, checkSubscription } = useCheckSubscription(requiredDataType, {
    "Context source": "export button",
  });

  const sendTrackingEvent = React.useCallback(
    (format) => {
      if (eventName) {
        logEvent(eventName, { ...eventAttributes, format });
      }
    },
    [eventName, logEvent, eventAttributes],
  );

  const menu: MenuProps = React.useMemo(() => {
    if (typeof exportAction === "function") {
      const handleDownload = (format: "xlsx" | "csv") => {
        return async () => {
          if (!checkSubscription()) {
            return;
          }
          const [filename, blob] = await exportAction(format);
          downloadFile(blob, `${filename}.${format}`);
          sendTrackingEvent(format);
        };
      };

      return {
        items: [
          {
            key: "xlsx",
            onClick: window.guestUser ? openGuestAccessPassPaywall : handleDownload("xlsx"),
            label: "Export to XLSX",
          },
          {
            key: "csv",
            onClick: window.guestUser ? openGuestAccessPassPaywall : handleDownload("csv"),
            label: "Export to CSV",
          },
        ],
      };
    } else {
      return {
        items: [
          {
            key: "xlsx",
            label: (
              <TextLink
                to={exportAction.xlsx}
                onClick={() => {
                  sendTrackingEvent("xlsx");
                }}
                authorised={authorised}
                onUnauthorisedClick={checkSubscription}
              >
                Export to XLSX
              </TextLink>
            ),
          },
          {
            key: "csv",
            label: (
              <TextLink
                to={exportAction.csv}
                onClick={() => {
                  sendTrackingEvent("csv");
                }}
                authorised={authorised}
                onUnauthorisedClick={checkSubscription}
              >
                Export to CSV
              </TextLink>
            ),
          },
        ],
      };
    }
  }, [authorised, checkSubscription, exportAction, sendTrackingEvent, openGuestAccessPassPaywall]);

  return (
    <Dropdown menu={menu} disabled={disabled}>
      <Tooltip title={disabledReason} placement="bottomLeft">
        <Button
          className={className}
          size={small ? "small" : undefined}
          disabled={disabled}
          loading={loading}
        >
          <DownloadOutlined /> {label || "Export"}
          {!authorised && <PaywallStar className={css.paywallIcon} />}
          <DownOutlined style={{ fontSize: "12px" }} />
        </Button>
      </Tooltip>
    </Dropdown>
  );
}

type AdvancedExportButtonProps = {
  exportAction:
    | Record<"xlsx" | "csv", string>
    | ((format: "xlsx" | "csv") => Promise<[string, Blob]>);
  exportLimit?: number;
  resultsCount: number;
  requiredDataType?: ProHelperDataTypes;
  exportedDataType: EventDataTypes;
  loading?: boolean;
  className?: string;
};

export function LimitedExportButton({
  exportAction,
  exportLimit,
  resultsCount,
  requiredDataType,
  className,
  exportedDataType,
  loading,
}: AdvancedExportButtonProps) {
  const { openSupportChat } = useSupportChat();
  const subscription = useSubscription();
  const { trackingData } = useTracking();
  const exportLimitReached = exportLimit && resultsCount > exportLimit;
  const hasExportPermission = window.currentUser?.has_export_permission;

  if (exportLimitReached && !hasExportPermission) {
    let note: JSX.Element;
    if (subscription.onTrial()) {
      note = (
        <p>
          Exports of Stotles Pro data are not automatically enabled as part of the trial. If you
          would like to try out this functionality please
          <TextButton
            size="small"
            onClick={() =>
              openSupportChat((trackingData && trackingData["Page source"]) || "Search Table")
            }
          >
            get in touch
          </TextButton>
        </p>
      );
    } else if (!requiredDataType || subscription.hasSubscribedDataTypes(requiredDataType)) {
      note = (
        <span className={css.disabledNoteTooltip}>
          {`Narrow results to ${exportLimit} records or less to export. Need more? `}
          <br />
          <TextButton
            onClick={() =>
              openSupportChat((trackingData && trackingData["Page source"]) || "Search Table")
            }
          >
            Chat with our team
          </TextButton>{" "}
          to get started.
        </span>
      );
    } else {
      note = <span className={css.disabledNoteTooltip}>Please upgrade to export</span>;
    }

    return (
      <ExportButton
        className={className}
        exportAction={exportAction}
        eventName={EventNames.exportedData}
        requiredDataType={requiredDataType}
        label={`Export all`}
        disabled={true}
        disabledReason={note}
        loading={loading}
      />
    );
  }

  return (
    <TrackingProvider data={{ Count: resultsCount, "Data type": exportedDataType }}>
      <ExportButton
        className={className}
        exportAction={exportAction}
        eventName={EventNames.exportedData}
        requiredDataType={requiredDataType}
        label={`Export (${resultsCount})`}
      />
    </TrackingProvider>
  );
}

export default ExportButton;
