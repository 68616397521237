import React from "react";
import Button from "antd/lib/button";
import { CheckboxChangeEvent } from "antd5/es/checkbox";
import { Formik, FormikConfig, useFormikContext } from "formik";

/*
 * These types & components should make it easier to work with formik
 * by not injecting formik data & handlers into commonly used components.
 */

/** Base type for Formik form state */
type BaseFormValueType = Record<string, string | number | string[]>;

/** Minimum props for an input to be useful to formik */
type BaseInputComponentProps = {
  field: string;
  error?: string;
  name: string; // This is required for Formik to work
};

/**
 * Minimum props that the input we're wrapping has to support.
 */
type RequiredInputProps = {
  // These are the value types that can be passed in via the antd input components and are accepted by formik
  value?: string | number | string[];
  onChange?:
    | ((e: CheckboxChangeEvent) => void) // ant's checkbox uses a non-standard event type
    | React.InputHTMLAttributes<HTMLInputElement>["onChange"];
  onBlur?: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
};

/**
 * Wraps any input Component by injecting data from fromik context.
 */
export function FormikedInput<Props extends BaseInputComponentProps>(
  Component: React.ComponentType<Props & RequiredInputProps>,
) {
  const wrappedComponent = function <FormType extends BaseFormValueType>(
    props: Props & { name: keyof FormType },
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { errors, values, handleChange, handleBlur } = useFormikContext<FormType>();
    return (
      <Component
        {...props}
        error={errors[props.name]}
        value={values[props.name]}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    );
  };

  return wrappedComponent;
}

type FormikFormProps<FormType> = React.PropsWithChildren<FormikConfig<FormType>> & {
  /** Defaults to "Save" */
  submitLabel?: string;
};

/**
 * Simple form component using Formik. Contains a submit button.
 * Children can use formik context to read formik state (either directly or using `FormikedComponent`).
 */
export function AdminFormikForm<FormType>({
  children,
  submitLabel,
  ...props
}: FormikFormProps<FormType>): JSX.Element {
  return (
    <Formik<FormType> {...props}>
      {({ handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          {children}
          <Button
            htmlType="submit"
            loading={isSubmitting}
            disabled={!isValid || !dirty}
            title={dirty ? "No changes to save." : undefined}
          >
            {submitLabel || "Save"}
          </Button>
        </form>
      )}
    </Formik>
  );
}
