import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableDocuments } from "components/documents/FilterableDocuments";
import { DEFAULT_DOCUMENT_FILTERS } from "components/documents/utils";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { TrackingProvider } from "lib/tracking";

import css from "./DocumentsPage.module.scss";

export function DocumentsPage() {
  const { authorised: hasDocuments } = useCheckSubscription("DOCUMENTS");

  return (
    <DetailsPage>
      <DetailsHeader>
        <h1 className={css.title}>Documents</h1>
      </DetailsHeader>
      <TrackingProvider data={{ "Page source": "Document search" }}>
        {!hasDocuments && <PaywallBanner featureType="DOCUMENTS" />}
        <DetailsContent className={css.content}>
          <FilterableDocuments defaultFilters={{ ...DEFAULT_DOCUMENT_FILTERS }} showBanner={true} />
        </DetailsContent>
      </TrackingProvider>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(DocumentsPage, {
    pageName: "Documents",
  }),
);
