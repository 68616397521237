import { graphql } from "lib/generated/app-service-gql";
import {
  FileExportSubscription,
  FileExportSubscriptionVariables,
} from "lib/generated/app-service-gql/graphql";
import { useSubscription } from "../useGraphQlClient";

// adding this so it generates the types
const _fileExport = graphql(`
  subscription fileExport($id: String!) {
    fileExport(id: $id) {
      status
      isComplete
      isError
      downloadUrl
    }
  }
`);

type UseFileExportStatusVars = {
  exportId?: string;
  onSuccess: (downloadUrl: string) => void;
  onError: () => void;
};

export function useFileExportStatus(exportVars: UseFileExportStatusVars, enabled = true) {
  const { exportId, onSuccess, onError } = exportVars || {};

  return useSubscription<FileExportSubscription, FileExportSubscriptionVariables>(
    `
subscription fileExport($id: String!) {
    fileExport(id: $id) {
      status
      isComplete
      isError
      downloadUrl
    }
  }
`,
    { id: exportId || "" },
    {
      enabled: !!exportId && enabled,
      onData: (data) => {
        const { downloadUrl, isError } = data.fileExport;
        if (downloadUrl) {
          onSuccess(downloadUrl);
        }

        if (isError) {
          onError();
        }
      },
    },
  );
}
