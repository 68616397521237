import React, { useState } from "react";
import { FieldValues, useController } from "react-hook-form";

import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { useDebouncedValue } from "lib/debounce";
import { useSearchFrameworks } from "lib/hooks/api/frameworks/useSearchFrameworks";
import { SearchRequestSortOrder } from "lib/types/graphQLEnums";
import { Select, SelectProps } from "./Inputs";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

const DEFAULT_FRAMEWORK_SELECT_FILTERS = {
  sort: "title",
  limit: 10,
  page: 1,
  sortOrder: SearchRequestSortOrder.Asc,
};

export function FrameworkSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { field } = useController(props);

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);

  const enableInitialSearch =
    !!field.value && !debouncedText && !!(field.value as string[])?.length;

  // Used for fetching the currently filtered framework IDs - used upon reuse of this component
  const { data: initialFrameworks } = useSearchFrameworks(
    { frameworkIds: field.value, ...DEFAULT_FRAMEWORK_SELECT_FILTERS },
    false,
    {
      enabled: enableInitialSearch,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const { data, isLoading } = useSearchFrameworks(
    {
      searchText: debouncedText,
      ...DEFAULT_FRAMEWORK_SELECT_FILTERS,
    },
    false,
    {
      enabled: !enableInitialSearch,
    },
  );

  let options = data?.results
    ? data.results.map((framework) => ({
        label: framework.title,
        value: framework.id,
      }))
    : [];

  if (!debouncedText && initialFrameworks?.results && options.length === 0) {
    options = initialFrameworks.results.map((framework) => ({
      label: framework.title,
      value: framework.id,
    }));
  }

  // TODO: Add paywall here when data type for frameworks is created
  return (
    <RedactedWrapper
      redactContent={
        <Select
          {...props}
          selectAll={!!debouncedText}
          loading={isLoading && !!debouncedText}
          options={options}
          optionFilterProp="label"
          onSearch={(val) => setSearchText(val)}
          showSearch
          filterOption={false}
          disabled
        />
      }
      // TODO Add framework paywall here
      // requiredDataType="SUPPLIERS"
      contextSource="Framework filter"
    >
      <Select
        {...props}
        selectAll={!!debouncedText}
        loading={isLoading && !!debouncedText}
        options={options}
        optionFilterProp="label"
        onSearch={(val) => setSearchText(val)}
        showSearch
        filterOption={false}
      />
    </RedactedWrapper>
  );
}
