import * as React from "react";
import { hot } from "react-hot-loader/root";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { redirectToSignUp } from "lib/userUtils";
import { useSupportChat } from "lib/utils/useSupportChat";

import css from "./AuthenticationFailure.module.scss";

type Props = {
  errorType: string;
  errorDescription?: string;
};

const NO_PERSONAL_EMAILS_ERROR = "no_personal_emails";
const INACTIVE_ACCOUNT_ERROR = "inactive";
const SELF_SIGNUP_NOT_ALLOWED_ERROR = "signup_not_allowed";

function AuthenticationFailurePage({ errorType, errorDescription }: Props) {
  const { openSupportChat } = useSupportChat();

  const content = React.useMemo(() => {
    if (errorDescription === NO_PERSONAL_EMAILS_ERROR)
      return {
        header: "Stotles requires a work email to complete signup",
        description: `It looks like you tried to log in with a personal email. 
      We only allow sign ups from emails with a company domain (e.g. ‘name@yourcompany.com’)`,
        actionable: `Your work email connects you to your company’s teams and unlocks powerful collaboration features.`,
      };

    switch (errorType) {
      case INACTIVE_ACCOUNT_ERROR:
        return {
          header: "Your account has been deactivated",
          description: `Please contact our Customer Support team - we’ll try and fix 
          the problem as soon as possible.`,
          actionable: null,
        };
      case SELF_SIGNUP_NOT_ALLOWED_ERROR:
        return {
          header: "Something went wrong",
          description: "We’re sorry - your organisation doesn’t allow self sign up.",
          actionable:
            "Please notify Stotles customer support or your company's Stotles admin to have your account created.",
        };

      default:
        return {
          header: "Something went wrong",
          description: "We’re sorry - an unknown authentication error has occurred. ",
          actionable:
            "Please contact our Customer Support team - we’ll try and fix the problem as soon as possible.",
        };
    }
  }, [errorType, errorDescription]);

  return (
    <>
      <div className={css.backButtonContainer}>
        <TextButton onClick={redirectToSignUp} className={css.backButton}>
          <ArrowLeftOutlined /> Back
        </TextButton>
      </div>
      <div className={css.authenticationFailurePage}>
        <div className={css.authenticationFailureContainer}>
          <h1 className={css.headerTitle}>{content.header}</h1>
          <div className={css.description}>
            <p>{content.description}</p>
            {content.actionable && <p>{content.actionable}</p>}
          </div>
        </div>
        <div className={css.actionItemsContainer}>
          {errorDescription == "no_personal_emails" && (
            <Button
              type="primary"
              block
              className={css.switchToWorkEmailButton}
              onClick={redirectToSignUp}
            >
              Switch to a work email
            </Button>
          )}

          <Button
            type={errorDescription === "no_personal_emails" ? "default" : "primary"}
            onClick={() => openSupportChat(`Signup/Login failure`)}
          >
            Speak to Customer Support
          </Button>
        </div>
      </div>
    </>
  );
}

const pageName = (errorType?: string, errorDescription?: string) => {
  if (errorDescription === NO_PERSONAL_EMAILS_ERROR) {
    return "Personal Email Error";
  }

  switch (errorType) {
    case INACTIVE_ACCOUNT_ERROR:
      return "Inactive Account Error";
    case SELF_SIGNUP_NOT_ALLOWED_ERROR:
      return "Self Signup Not Allowed Error";
    default:
      return "Signup Generic Error";
  }
};

export default hot(
  withAppLayout(AuthenticationFailurePage, (props) => ({
    pageName: pageName(props.errorType, props.errorDescription),
    hideMenuItems: true,
    hideUserItems: true,
  })),
);
