import React from "react";
import { LockOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Progress, Tooltip } from "antd5";

import { assertCurrentUser } from "lib/currentUser";
import { TeamsQuery } from "lib/generated/app-service-gql/graphql";
import { useHardDeleteTeamFromOnboarding } from "lib/hooks/api/teams/useHardDeleteTeamFromOnboarding";
import { useSwitchTeam } from "lib/hooks/api/teams/useSwitchTeam";
import * as tracking from "lib/tracking";
import { useSupportChat } from "lib/utils/useSupportChat";
import { fieldInteractionEvent } from "./tracking";

import css from "./JoinTeamPage.module.scss";

type JoinTeamPageProps = {
  teams: TeamsQuery["teams"];
  companyName: string;
  onJoinTeam: () => void;
  onCreateTeam: () => void;
};

function JoinTeamPage({ teams, companyName, onJoinTeam, onCreateTeam }: JoinTeamPageProps) {
  const { openSupportChat } = useSupportChat();
  const user = assertCurrentUser();
  const currentTeamId = user.team.id;
  const [selectedPrivateTeam, setSelectedPrivateTeam] = React.useState<string | undefined>(
    undefined,
  );
  const { mutate: deleteTeam } = useHardDeleteTeamFromOnboarding();
  const { mutate: switchTeam } = useSwitchTeam({
    onSuccess: (_data, variables) => {
      tracking.logEvent(tracking.EventNames.joinTeamClicked, {
        "Page source": "Onboarding - Team - Join Team",
        "Team name": variables.name,
      });
      deleteTeam({ input: { id: currentTeamId } });
      onJoinTeam();
    },
  });

  const footer = (
    <>
      <Button
        key="support"
        type="primary"
        onClick={() => {
          tracking.logEvent(tracking.EventNames.contactSupportClicked, {
            "Page source": "Onboarding - Team - Join Team",
            "Team name": selectedPrivateTeam,
            "Context source": "Request to join team modal",
          });
          openSupportChat("Onboarding - Team - Join Team");
        }}
      >
        Contact Support
      </Button>
    </>
  );

  return (
    <div>
      <Modal
        title="You'll need to contact us to join this team"
        open={!!selectedPrivateTeam}
        onCancel={() => {
          tracking.logEvent(tracking.EventNames.joinTeamModalClosed, {
            "Page source": "Onboarding - Team - Join Team",
            "Team name": selectedPrivateTeam,
            "Context source": "Request to join team modal",
          });
          setSelectedPrivateTeam(undefined);
        }}
        footer={footer}
      >
        <p className={css.modalBodyText}>
          {selectedPrivateTeam} is a private team, so a member of our Customer Support team needs to
          approve your access.
        </p>
        <p className={css.modalBodyText}>
          You can contact support via the button below. We’ll get you onboarded into{" "}
          {selectedPrivateTeam} as soon as possible.
        </p>
      </Modal>

      <div className={css.progressBar}>
        <Progress percent={75} showInfo={false} />
      </div>
      <div className={css.header}>
        <h1 className={css.title}>We found you a team!</h1>
        <p className={css.subTitle}>
          Other people from {companyName} are already using Stotles teams. You can join one of
          theirs, or make your own.
        </p>
      </div>
      <div className={css.teams}>
        {teams?.map((team) => {
          const displayedTeamMembers = team.users.slice(0, 2);
          const moreTeamMembers = team.users.length - displayedTeamMembers.length;
          return (
            <div key={team.id} className={css.team}>
              <div className={css.teamDetails}>
                <h2 className={css.teamName}>{team.name}</h2>
                {team.users && team.users.length > 0 && (
                  <p className={css.teamMembers}>
                    {displayedTeamMembers.map((user) => user.firstName).join(", ")}
                    {moreTeamMembers >= 0 && ` + ${moreTeamMembers} other members`}
                  </p>
                )}
              </div>
              {team.isPrivate ? (
                <Tooltip title="This is a private team, so an admin needs to approve your access.">
                  <Button
                    className={css.joinButton}
                    onClick={() => {
                      fieldInteractionEvent(team.name, "requestPrivateTeam");
                      setSelectedPrivateTeam(team.name);
                    }}
                    icon={<LockOutlined />}
                  >
                    Request
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  type="primary"
                  className={css.joinButton}
                  onClick={() => {
                    fieldInteractionEvent(team.name, "joinTeam");
                    switchTeam({
                      id: team.id,
                      name: team.name,
                    });
                  }}
                >
                  Join
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <div className={css.createTeamSection}>
        <Button
          type="text"
          className={css.createTeamButton}
          onClick={() => {
            tracking.logEvent(tracking.EventNames.createNewTeamClicked, {
              "Page source": "Onboarding - Team - Join Team",
              "Context source": "On page",
            });
            fieldInteractionEvent("newTeam", "createTeam");
            onCreateTeam();
          }}
        >
          <PlusOutlined /> Create a new team
        </Button>
      </div>
      <div className={css.supportSection}>
        <p>
          Can't see your team?{" "}
          <Button
            className={css.supportButton}
            onClick={() => {
              tracking.logEvent(tracking.EventNames.contactSupportClicked, {
                "Page source": "Onboarding - Team - Join Team",
                "Context source": "On page",
              });
              openSupportChat("Onboarding - Team - Can't find team");
            }}
            type="text"
          >
            Contact support
          </Button>
        </p>
      </div>
    </div>
  );
}

export default JoinTeamPage;
