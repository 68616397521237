import * as React from "react";

import ContactsTable from "components/contacts/ContactsTable";
import { SecondaryTabs, TabPane } from "lib/core_components/tabs";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { BuyerDetails } from "lib/types/models";
import { HandleNavigation, SecondaryTab } from "../types";
import { ProcurementContacts } from "./Procurement";

import css from "./ContactsPanel.module.scss";

type PanelProps = {
  buyer: BuyerDetails;
  handleTabNavigation: HandleNavigation;
  activeTab: SecondaryTab | null;
};

export default function ContactsPanel({
  buyer,
  handleTabNavigation,
  activeTab,
}: PanelProps): JSX.Element {
  const { authorised: hasContacts } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Decision maker contacts table",
  });

  return (
    <>
      <SecondaryTabs
        activeKey={activeTab ? activeTab : SecondaryTab.CONTACTS}
        onChange={(k) => {
          handleTabNavigation(k as SecondaryTab);
        }}
        className={css.tabs}
      >
        <TabPane
          key={SecondaryTab.CONTACTS}
          tab={<>Decision-makers{!hasContacts && <PaywallStar className={css.paywallIcon} />}</>}
          className={css.paneClass} // this forces the tab to be rendered even if it isn't visible, which ensure that
          // all the data is eagerly fetched (so tab counts can be suitably populated)
          forceRender
        >
          <div className={css.contactsContent}>
            <ContactsTable
              buyerId={buyer.guid}
              contextSource={"Decision maker contacts table"}
              pageSize={10}
              showDropdownFilters={true}
              showAllFiltersDrawer={false}
            />
          </div>
        </TabPane>

        <TabPane
          key={SecondaryTab.PROCUREMENT}
          tab={"Procurement"}
          className={css.paneClass} // this forces the tab to be rendered even if it isn't visible, which ensure that
          // all the data is eagerly fetched (so tab counts can be suitably populated)
          forceRender
        >
          <div className={css.contactsContent}>
            <ProcurementContacts buyer={buyer} />
          </div>
        </TabPane>
      </SecondaryTabs>
    </>
  );
}
