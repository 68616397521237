import React from "react";

import css from "./EmptyDocument.module.scss";

import EmptyDocumentsSvg from "../../../assets/images/empty_box.svg";

export default function EmptyDocument() {
  return (
    <div className={css.container}>
      <img src={EmptyDocumentsSvg} alt="box" width={110} height={94} />
      <h3>No results, but don't give up!</h3>
      <p>
        Why not try searching for a different keyword? We recommend trying a range of keywords to
        make sure you're uncovering all insights.
      </p>
    </div>
  );
}
