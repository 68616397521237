import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import { TextLink } from "components/actions/Links";
import { useDocumentTracking } from "./tracking";

import css from "./DocumentHeader.module.scss";

type DocumentHeaderProps = {
  documentId: string;
  title: string;
  downloadUrl?: string;
  disableTitleLink?: boolean;
};

export default function DocumentHeader({
  documentId,
  title,
  downloadUrl,
  disableTitleLink,
}: DocumentHeaderProps) {
  const tracking = useDocumentTracking();

  return (
    <div className={css.header}>
      <Tooltip title={title} placement="bottom">
        <div className={css.ellipsisWrapper}>
          <TextLink
            className={css.title}
            targetType="new-tab"
            to={`/documents/${documentId}`}
            disabled={disableTitleLink}
          >
            {title}
          </TextLink>
        </div>
      </Tooltip>
      <div className={css.actions}>
        {downloadUrl && (
          <Button
            download
            href={downloadUrl ?? ""}
            icon={<DownloadOutlined className={css.downloadIcon} />}
            onClick={tracking.exportedData}
          />
        )}
      </div>
    </div>
  );
}
