import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NoticeDocsQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const noticeDocsQuery = graphql(`
  query noticeDocs($request: NoticeDocumentsRequest!) {
    noticeDocuments(NoticeDocumentsRequest: $request) {
      documents {
        id
        name
        noticeId
      }
    }
  }
`);

export type NoticeDocument = { id: string; name: string; noticeId: string };

export function useNoticeDocuments(
  noticeId: string,
  options?: UseQueryOptions<NoticeDocsQuery, unknown, NoticeDocsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["noticeDocuments", noticeId],
    noticeDocsQuery,
    [{ request: { noticeId } }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: data?.noticeDocuments.documents,
    ...rest,
  };
}
