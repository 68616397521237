import { isEqual, pickBy, startCase } from "lodash";

import { NoticeFilters } from "components/notices/utils";
import { SignalSettings } from "lib/hooks/api/teams/useSignalSettingsGQL";
import { EventData, EventDataTypes, EventNames, TrackingEvent } from "lib/tracking";
import { handleSavedSignalTrackingData } from "lib/utils/signalUtils";

export function createFeedFilterChangedEvents(
  previous: NoticeFilters,
  current: NoticeFilters,
  signalSettings: SignalSettings | undefined,
): TrackingEvent[] {
  // find the field difference between the current and previously applied filter object
  // the difference is the filter field that has just been applied
  const diff = pickBy(
    current,
    (value, key) => !isEqual(previous[key as keyof NoticeFilters], value),
  );

  return Object.entries(diff).map(([key, value]) => {
    let data: EventData = {
      "Context source": "Feed filter slide-out",
      "Filter name": startCase(key),
      "Filter selection": value,
      "Action type":
        Array.isArray(value) && value.length === 0 ? "Filter cleared" : "Filter applied",
      "Data type": EventDataTypes.record,
    };

    if (Array.isArray(value) && signalSettings) {
      data = handleSavedSignalTrackingData(data, key, value as string[], signalSettings);
    }

    return { name: EventNames.filterActioned, data };
  });
}

export function createSavedViewSelectedEvent(
  viewName?: string,
  viewId?: string,
  contextSource?: string,
) {
  return {
    "Saved view name": viewName ?? "",
    "Saved view id": viewId ?? "",
    "Data type": EventDataTypes.savedView,
    "Context source": contextSource ?? "Quick select",
  };
}

export function createSavedViewUnpinnedEvent(editType: string, viewName: string, viewId?: string) {
  return {
    "Saved view id": viewId ?? "",
    "Data type": EventDataTypes.savedView,
    "Edit type": editType,
    "Pinned View name": viewName,
  };
}

export function createSavedViewPinnedEvent(
  editType: string,
  viewName: string,
  contextSource: string,
  viewId?: string,
) {
  return {
    "Saved view id": viewId ?? "",
    "Data type": EventDataTypes.savedView,
    "Edit type": editType,
    "Pinned View name": viewName,
    "Context source": contextSource,
  };
}

export function createSavedViewUpdatedEvent(
  viewName: string,
  contextSource: string,
  viewId?: string,
  alertModificationType?: string,
  alertFrequency?: string,
) {
  const data = {
    "Saved view name": viewName,
    "Saved view id": viewId ?? "",
    "Data type": EventDataTypes.savedView,
    "Context source": contextSource,
  };

  if (alertModificationType) {
    return { ...data, "Alert modification type": alertModificationType, Frequency: alertFrequency };
  }

  return data;
}

export function createSavedViewCreatedEvent(name: string, id?: string) {
  return {
    "Saved view name": name,
    "Saved view id": id ?? "",
    "Data type": EventDataTypes.savedView,
    "Context source": "Save new button",
  };
}

export function createEditAlertsModalOpened(viewName: string, viewId?: string) {
  return {
    "Context Source": EventDataTypes.savedView,
    "Page source": "My Feed",
    "Saved view id": viewId ?? "",
    "Saved view name": viewName,
  };
}

export function createAlertsUpdatedEvent(
  name: string,
  modificationType: string,
  alertFrequency: string,
  viewId?: string,
) {
  return {
    "Context source": "Set alert modal",
    "Modification type": modificationType,
    Frequency: alertFrequency,
    "Saved view id": viewId ?? "",
    "Saved view name": name,
  };
}
