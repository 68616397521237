import * as React from "react";
import { PlusOutlined, SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Empty, Input, message, Select, Spin } from "antd5";

import UserInitials from "components/comments/UserInitials";
import SendInviteModal from "components/modals/SendInviteModal";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { createUseDebounce } from "lib/debounce";
import {
  TeamManagementUser as User,
  TeamManagementUserPaymentTypeEnum as PaymentType,
} from "lib/generated/app-api";
import { useTeams } from "lib/hooks/api/teams/useTeams";
import { useUserManagement } from "lib/hooks/api/users/useUserManagement";
import { useDialogManager } from "lib/providers/DialogManager";
import * as tracking from "lib/tracking";
import { pluralise } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";

import css from "./UserManagement.module.scss";

const PAYMENT_TYPE_LABELS = {
  [PaymentType.Freemium]: "Freemium",
  [PaymentType.Paid]: "Paid",
};

const COLUMNS: ColumnType<User>[] = [
  {
    key: "name_and_email",
    render: (_: unknown, user: User) => (
      <div className={css.nameCell}>
        <UserInitials
          firstName={user.firstName}
          lastName={user.lastName}
          className={css.userInitials}
        />
        <div className={css.userName}>
          {user.firstName} {user.lastName}
        </div>
        <div className={css.userEmail}>{user.email}</div>
      </div>
    ),
    title: "Name",
  },
  {
    title: "Team",
    key: "team",
    render: (_: unknown, user: User) => <div>{user.team.name}</div>,
  },
  {
    title: "License",
    key: "license",
    render: (_: unknown, user: User) => {
      return <div>{PAYMENT_TYPE_LABELS[user.paymentType] || user.paymentType}</div>;
    },
  },
  // {
  //   title: "Last activity",
  //   key: "last_activity",
  //   render: (_: unknown, _user: User) => (
  //     <div>{toHumanRelativeTimeFromNow(new Date(2024, 3, 20))}</div>
  //   ),
  // },
  {
    title: "Created",
    key: "created",
    render: (_: unknown, user: User) => <div>{user.createdAt.substring(0, 10)}</div>,
  },
];

type UserFilters = {
  text: string;
  teamId: string;
  paymentType: string;
};

const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

const useDebounce = createUseDebounce(500);

const PAYMENT_TYPE_OPTIONS = {
  __ALL_LICENSES__: "All licenses",
  [PaymentType.Paid]: "Paid",
  [PaymentType.Freemium]: "Freemium",
} as const;

const ALL_TEAMS = "__ALL_TEAMS__";
const ALL_LICENSES = "__ALL_LICENSES__";

function UserManagement(): JSX.Element {
  const { openSupportChat } = useSupportChat();
  const dialogManager = useDialogManager();
  const [pagination, setPagination] = React.useState(DEFAULT_PAGINATION);
  const [filters, _setFilters] = React.useState<UserFilters>({
    text: "",
    teamId: ALL_TEAMS,
    paymentType: ALL_LICENSES,
  });
  const [textInputValue, setTextInputValue] = React.useState("");

  // Hook to fetch the available teams within a company
  const {
    isLoading: loadingUsers,
    data: users,
    isError,
  } = useUserManagement(
    {
      text: filters.text ? filters.text.toLowerCase() : undefined,
      paymentType: filters.paymentType === ALL_LICENSES ? undefined : filters.paymentType,
      teamId: filters.teamId === ALL_TEAMS ? undefined : filters.teamId,
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
    },
    {
      onError() {
        void message.error("Unable to get users; please contact us if the problem persists");
      },
    },
  );

  const { isLoading: loadingTeams, data: teams } = useTeams();

  const setFilters = useDebounce((f: UserFilters) => {
    _setFilters(f);
    setPagination((old) => ({ ...old, current: 1 }));
  });

  const filterElements = (
    <div className={css.filters}>
      <Input
        placeholder="Search users"
        prefix={<SearchOutlined />}
        value={textInputValue || undefined}
        onChange={(e) => {
          setTextInputValue(textInputValue);
          setFilters({ ...filters, text: e.target.value });
        }}
        allowClear
        className={css.input}
      />
      <Select
        value={filters.paymentType}
        onChange={(val) => {
          setFilters({ ...filters, paymentType: val });
        }}
        className={css.select}
      >
        {Object.entries(PAYMENT_TYPE_OPTIONS).map(([val, label]) => (
          <Select.Option key={val} value={val} label={label} title={label}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <Select
        value={filters.teamId}
        loading={loadingTeams}
        onChange={(val) => {
          setFilters({ ...filters, teamId: val });
        }}
        className={css.select}
      >
        <Select.Option key="all" label="All teams" value="__ALL_TEAMS__">
          All teams
        </Select.Option>
        {teams?.map((t) => (
          <Select.Option key={t.id} label={t.name} value={t.id}>
            {t.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  return (
    <tracking.TrackingProvider
      data={{
        "Context source": "Account-Management - My Team",
      }}
    >
      <div className={css.teamsPageContainer}>
        <div className={css.teamMembersContainer}>
          <div className={css.myTeam}>
            <div>
              <h2 className={css.title}>
                Users{" "}
                <span className={css.actions}>
                  {/*
                  TODO: implement CSV download
                  <Button icon={<DownloadOutlined />}>Download CSV</Button>
                  */}
                  <Button
                    type="primary"
                    onClick={() => dialogManager.openDialog(SendInviteModal, {})}
                  >
                    <PlusOutlined />
                    <span>Invite user</span>
                  </Button>
                </span>
              </h2>
              <Alert
                type="warning"
                description={
                  <div className={css.editTeam}>
                    To edit team members, licenses or teams please get in contact with our Customer
                    Support Team who will be happy to help.
                    <Button
                      onClick={() => openSupportChat("'Contact support' on user management page")}
                    >
                      Contact support
                    </Button>
                  </div>
                }
                className={css.addTeamsMessage}
              />
              {filterElements}
              <div className={css.usersCount}>
                {loadingUsers ? <Spin /> : pluralise(users?.pagingInfo.totalResults || 0, "user")}
              </div>
              <Table
                columns={COLUMNS}
                dataSource={users?.results}
                loading={loadingUsers}
                pagination={{
                  ...pagination,
                  total: users?.pagingInfo.totalResults || 0,
                  onChange: (page, pageSize) => {
                    setPagination({ current: page, pageSize });
                  },
                  showSizeChanger: true,
                }}
                rowKey="guid"
                locale={{
                  emptyText: isError ? (
                    <Empty
                      image={<WarningOutlined style={{ fontSize: 100 }} />}
                      description="Sorry something has gone wrong"
                    />
                  ) : undefined,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </tracking.TrackingProvider>
  );
}

export default UserManagement;
