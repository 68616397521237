import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { App, Button, Dropdown, MenuProps, Tooltip } from "antd5";

import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";
import {
  ExportBuyersRequestFormatEnum,
  useExportBuyers,
} from "lib/hooks/api/suppliers/useExportBuyers";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { downloadFile } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";
import { TableContext } from "./types";

import css from "./ExportBuyersButton.module.scss";

const BUYER_EXPORT_LIMIT = 500;

export function ExportBuyersButton({
  filters,
  selectedBuyers,
  tableContext,
}: {
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  selectedBuyers?: string[];
  // TODO: Fetch this from react context using a hook
  tableContext: TableContext;
}) {
  const { openSupportChat } = useSupportChat();

  const { logEvent, trackingData } = useTracking();

  const buyersButtontrackingData = { ...trackingData, "CTA actioned": "Export" };

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(buyersButtontrackingData);

  const { message } = App.useApp();

  const {
    totalCount,
    queryResult: { isLoading },
  } = tableContext;

  const { mutate, isLoading: isExporting } = useExportBuyers({
    onError: () => message.error("Failed to download file"),
    onSuccess: (data, variables) => {
      downloadFile(data, `buyers-export.${variables?.format}`);
    },
  });

  const exportCount =
    selectedBuyers && selectedBuyers.length > 0 ? selectedBuyers.length : totalCount;

  const menuItems: MenuProps["items"] = [
    {
      key: "xlsx",
      label: "Export to XLSX",
    },
    {
      key: "csv",
      label: "Export to CSV",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }: { key: ExportBuyersRequestFormatEnum }) => {
    if (window.guestUser) {
      return openGuestAccessPassPaywall();
    }

    logEvent(EventNames.exportedData, {
      Count: exportCount,
      Format: key,
      "Data type": EventDataTypes.buyer,
    });

    mutate({
      format: key,
      filters: { ...filters, buyerGuids: selectedBuyers },
      offset: 0,
      buyerGuids: selectedBuyers,
    });
  };

  const exportLimitReached = exportCount && exportCount > BUYER_EXPORT_LIMIT;
  const hasExportPermission = window.currentUser?.has_export_permission;

  if (exportLimitReached && !hasExportPermission) {
    return (
      <Tooltip
        title={
          <span>
            {`Narrow results to ${BUYER_EXPORT_LIMIT} notices or less to export. Need more? `}
            <br />
            <Button
              type="text"
              onClick={() => openSupportChat("Supplier search")}
              className={css.intercomButton}
            >
              Chat with our team
            </Button>{" "}
            to get started.
          </span>
        }
      >
        <Button icon={<DownloadOutlined className={css.exportIcon} />} disabled>
          Export All {`(${exportCount})`}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Dropdown menu={{ items: menuItems, onClick }} disabled={isExporting}>
      <Button
        loading={isLoading || isExporting}
        disabled={isLoading || isExporting}
        icon={<DownloadOutlined className={css.exportIcon} />}
      >
        Export {selectedBuyers && selectedBuyers.length === 0 && ` All (${totalCount || 0})`}
      </Button>
    </Dropdown>
  );
}
