import React, { useEffect } from "react";
import { Button, Spin } from "antd5";

import { useProcessNoticeDocument } from "../../../lib/hooks/api/bid/useProcessNoticeDocument";

import css from "./ProcessFile.module.scss";

type Props = {
  file: File;
  noticeId: string;
  documentId: string;
  onComplete: (fileName: string, isError: boolean) => void;
};

export function ProcessFile({ file, noticeId, documentId, onComplete }: Props) {
  const { status, mutate, error, isLoading, isSuccess } = useProcessNoticeDocument({
    onSettled: (_data, error) => {
      onComplete(file.name, !!error);
    },
  });

  useEffect(() => {
    if (status === "idle") {
      mutate({ input: { documentName: file.name, noticeId, documentId } });
    }
  }, [file, noticeId, status, mutate, documentId]);

  return (
    <div>
      <h4>
        {file.name} {error && ` - ${error.message}`}
      </h4>
      {!error && (
        <div className={css.statusContainer}>
          Processing file <Spin spinning={isLoading} percent={isSuccess ? 100 : undefined} />
        </div>
      )}
      {error && (
        <Button
          onClick={() => mutate({ input: { documentName: file.name, noticeId, documentId } })}
        >
          Retry
        </Button>
      )}
    </div>
  );
}
