import React from "react";
import { Button, Empty } from "antd"; //Probably should be a TextButton but check visually

import CentredSpinner from "lib/core_components/CentredSpinner";
import { PdfPreviewSlideout } from "lib/documents/PdfPreviewSlideout";
import { useDocuments } from "lib/hooks/api/buyer/useDocuments";
import { useStotlesApi } from "lib/stotlesApiContext";
import { BuyerDetails, DocumentMatch } from "lib/types/models";
import { useSupportChat } from "lib/utils/useSupportChat";
import { DocumentGroup } from "./DocumentGroup";
import { DocumentType } from "./documentUtils";

import css from "./DocumentSection.module.scss";

type Props = {
  buyer: BuyerDetails;
};

export type DocumentGroup = {
  documentType: DocumentType;
  documentMatches: DocumentMatch[];
};

export function DocumentSection({ buyer }: Props): JSX.Element {
  const [displayDoc, setDisplayDoc] = React.useState<string>();
  const [displayTerms, setDisplayTerms] = React.useState<string[]>();

  const { openSupportChat } = useSupportChat();

  const api = useStotlesApi();

  const { data, isLoading } = useDocuments({ ownerGuid: buyer.guid, fetch_all: true }, 10000, {
    // TODO: Enable this for guest users when backend problem is fixed
    enabled: !window.guestUser,
  });

  const handleDocumentSlideoutClose = () => {
    setDisplayDoc(undefined);
    setDisplayTerms(undefined);
  };

  const handleDocumentClick = React.useCallback(
    async (documentMatch: DocumentMatch) => {
      setDisplayDoc(documentMatch.document.id);
      if (documentMatch.signal_ids.length > 0) {
        const { matched_words } = await api.getDocumentHighlights(documentMatch.document.id);
        if (matched_words.length > 0) {
          setDisplayTerms(matched_words);
        }
      }
    },
    [api],
  );

  const documentGroups = React.useMemo(() => {
    const groups: React.ReactNode[] = [];
    if (data?.documents) {
      data.documents.forEach((group) => {
        groups.push(
          <DocumentGroup
            name={group.documentType}
            documents={group.documentMatches}
            signals={data?.signals}
            key={group.documentType}
            onDocumentClick={handleDocumentClick}
          />,
        );
      });
    }
    return groups;
  }, [data?.documents, data?.signals, handleDocumentClick]);

  if (isLoading && !window.guestUser) {
    return (
      <div className={css.documentSpinner}>
        <CentredSpinner />
      </div>
    );
  }

  return (
    <>
      {data?.documents && data.documents.length > 0 ? (
        data?.signals && <>{documentGroups}</>
      ) : (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <>
                <span className={css.noDocumentMessage}>
                  We haven't collected documents for this buyer yet.
                </span>
                {!window.guestUser && (
                  <Button
                    size="small"
                    type="link"
                    className={css.requestDocuments}
                    onClick={() =>
                      openSupportChat(`Request documents for ${buyer.name} ${buyer.guid}`)
                    }
                  >
                    Request documents for this buyer
                  </Button>
                )}
              </>
            }
          />
        </div>
      )}
      <PdfPreviewSlideout
        documentId={displayDoc}
        searchTerms={displayTerms}
        onSlideoutClose={handleDocumentSlideoutClose}
        caseSenstive
      />
    </>
  );
}
