import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button } from "antd5";

import { Input } from "components/form_components/Inputs";
import { ALL_NOTICE_COLUMNS } from "components/my_feed/useMyFeedPageState";
import { ExportNoticesButton } from "components/notices/ExportNoticesButton";
import { FilterForm, FilterFormLocation, groupedKeys } from "components/notices/FilterForm";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { ColumnSetting, ColumnSettingFieldEnum } from "lib/generated/app-api";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { SortState } from "lib/search/types";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import {
  FILTER_COLLAPSE_NOTICES,
  LAYER_CAKE_FILTERS,
  NEW_NOTICES_SEARCH,
} from "../../lib/featureFlags";
import { findKeybyValue } from "../../lib/utils";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import { NewNoticeTable } from "./NewNoticeTable";
import { NoticeColumns, NoticeTable } from "./NoticeTable";
import { QuickFilterBar } from "./QuickFilterBar";
import { useFilterableNoticeTableState } from "./useFilterableNoticeTableState";
import { FilterSection, NoticeFilters } from "./utils";

import css from "./FilterableNoticeTable.module.scss";

type FormValues = {
  text: string;
};

export function FilterableNoticeTable({
  defaultFilters,
  defaultColumns,
  hiddenSections,
  hiddenFilters,
  paywallEnabled,
  location,
}: {
  defaultFilters: NoticeFilters;
  defaultColumns: NoticeColumns[];
  hiddenSections?: FilterSection[];
  hiddenFilters?: (keyof NoticeFilters)[];
  paywallEnabled?: boolean;
  location?: FilterFormLocation;
}) {
  const { noticeTableFilters, setNoticeTableFilters, setNoticeTableColumns, tableColumns } =
    useFilterableNoticeTableState();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedNotices, setSelectedNotices] = useState<string[]>();
  const [activeGroups, setActiveGroups] = useState<string[]>([]);

  const subscription = useSubscription();

  const { logEvent, trackingData } = useTracking();

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(trackingData);

  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_NOTICES, false);

  const hasFrameworks =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  const currentFilters = noticeTableFilters ? noticeTableFilters : defaultFilters;
  //override signals & sector filter if layer cake filters are enabled
  const appliedFilters = useMemo(() => {
    if (!isLayerCakeFiltersEnabled) {
      return currentFilters;
    } else {
      //if layer cake is enabled, we need to override applied filters
      // only set supplierMentionType if supplierGuids are set
      currentFilters.supplierMentionType =
        currentFilters.supplierGuids && currentFilters.supplierGuids.length > 0
          ? currentFilters.supplierMentionType
          : undefined;
      //TODO: cleanup when layer cake is fully rolled out
      // clear sector filter as this is replaced by cpvCodes
      currentFilters.cpvDimensions = [];
      // clear signals filter as this is removed
      currentFilters.signals = [];

      return currentFilters;
    }
  }, [isLayerCakeFiltersEnabled, currentFilters]);
  const appliedColumns = tableColumns ? tableColumns : defaultColumns;

  const useDebounce500 = createUseDebounce(500);
  const debouncedSetNoticeTableFilters = useDebounce500(setNoticeTableFilters);
  const debouncedFreeSearchLog = useDebounce500(() => {
    logEvent(EventNames.freeSearch, {
      "Filter name": "Free search",
      "Action type": "Filter applied",
      "Data type": EventDataTypes.record,
    });
  });

  const loggedFilterOpen = () => {
    logEvent(EventNames.filterPanelOpened, {
      "Context source": "Filters button",
      "Data type": "Records",
    });
    setIsFilterDrawerOpen(true);
  };

  const values = appliedFilters;

  const { control, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      text: appliedFilters.text,
    },
    mode: "onChange",
    values,
  });

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        debouncedSetNoticeTableFilters({ ...appliedFilters, text: data.text });
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, appliedFilters, debouncedSetNoticeTableFilters]);

  const { authorised: hasAwards } = useCheckSubscription("AWARDS");

  return (
    <>
      {!hasAwards && paywallEnabled ? <PaywallBanner featureType="AWARDS" /> : null}
      <div className={css.filterBar}>
        <div className={css.filterItems}>
          <form
            className={css.searchInput}
            onSubmit={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              name="text"
              label=""
              control={control}
              allowClear
              onChange={() => debouncedFreeSearchLog()}
            />
          </form>
          <Button
            onClick={window.guestUser ? openGuestAccessPassPaywall : loggedFilterOpen}
            icon={<FilterOutlined className={css.filterIcon} />}
          >
            Filters
          </Button>
        </div>
        <div className={css.columnsAndExport}>
          <div className={css.clearSelected}>
            {selectedNotices && selectedNotices.length > 0 && (
              <>
                <ClearSelectedButton
                  count={selectedNotices.length}
                  onClear={() => setSelectedNotices([])}
                />
                :
              </>
            )}
          </div>
          <div className={css.editColumnsButton}>
            {selectedNotices && selectedNotices.length === 0 && (
              <EditColumnsButton
                selectedColumns={appliedColumns.map((c) => {
                  return {
                    field: c,
                    visible: true,
                  } as ColumnSetting;
                })}
                onNewColumnSettingChange={(columns) => {
                  setNoticeTableColumns(columns.map((c) => c.field) as ColumnSettingFieldEnum[]);
                }}
                allAvailableColumns={
                  hasFrameworks
                    ? [...ALL_NOTICE_COLUMNS, { title: "Framework", field: "framework" }]
                    : ALL_NOTICE_COLUMNS
                }
                dataType={EventDataTypes.record}
              />
            )}
          </div>
          <ExportNoticesButton filters={appliedFilters} selectedNotices={selectedNotices} />
        </div>
      </div>
      <div className={css.viewFilters}>
        <QuickFilterBar
          filters={appliedFilters}
          onClearFilter={(key) =>
            setNoticeTableFilters({
              ...appliedFilters,
              [key]: Array.isArray(defaultFilters[key]) ? [] : undefined,
            })
          }
          hiddenSections={hiddenSections}
          hiddenFields={hiddenFilters}
          onFilterClick={(key) => {
            setIsFilterDrawerOpen(true);
            setActiveGroups([findKeybyValue(key, groupedKeys) || ""]);
          }}
        />
      </div>

      {useOpenSearch ? (
        <NewNoticeTable
          filters={appliedFilters}
          columnSettings={appliedColumns}
          onSortChange={(sortOrder: SortState) =>
            setNoticeTableFilters({ ...appliedFilters, sort: sortOrder })
          }
          selectedRows={selectedNotices}
          onSelectedRowsChange={(rows) => setSelectedNotices(rows)}
        />
      ) : (
        <NoticeTable
          filters={appliedFilters}
          columnSettings={appliedColumns}
          onSortChange={(sortOrder: SortState) =>
            setNoticeTableFilters({ ...appliedFilters, sort: sortOrder })
          }
          selectedRows={selectedNotices}
          onSelectedRowsChange={(rows) => setSelectedNotices(rows)}
        />
      )}
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onChange={debouncedSetNoticeTableFilters}
            filters={appliedFilters}
            options={{
              disabledSections: hiddenSections ? hiddenSections : undefined,
            }}
            showTitle={isCollapseEnabled}
            onClose={() => setIsFilterDrawerOpen(false)}
            activeGroups={activeGroups}
            onActiveGroupsChange={setActiveGroups}
            location={location}
          />
        )}
      </FilterDrawer>
    </>
  );
}
