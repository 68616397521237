import React from "react";
import { Select } from "antd5";
import classnames from "classnames";
import { v4 as uuidV4 } from "uuid";

import SuggestedKeywords from "components/account_management/feed_settings/SuggestedKeywords";
import AutoclosingMultiselect from "components/form_components/AutoclosingMultiselect";
import { filterDuplicatesKeywords } from "components/onboarding/onboardingUtils";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { formatKeywordTag, getCountLabel, validateTerm } from "lib/previewQueryUtils";
import { completeUnclosedQuotes } from "lib/utils";
import { InfoButton } from "../actions/InfoButton";

import css from "components/form_components/KeywordsField.module.scss";

type KeywordFieldProps = {
  values?: string[];
  onChange: (values: string[]) => void;
  label?: React.ReactNode;
  hintLabel?: React.ReactNode;
  hintTooltip?: React.ReactNode;
  className?: string;
  keywordCounts?: { [keyword: string]: number };
  disabled?: boolean;
  showSuggestedKeywords?: boolean;
  feedPreviewLoading?: boolean;
};
const randomizedID = uuidV4();

const KeywordsField = React.forwardRef<HTMLDivElement, KeywordFieldProps>(
  (
    {
      values,
      onChange,
      label,
      className,
      keywordCounts,
      hintLabel,
      hintTooltip,
      disabled,
      showSuggestedKeywords,
      feedPreviewLoading,
    },
    ref,
  ) => {
    const [lastAddedKeyword, setLastAddedKeyword] = React.useState<string | null>(null);

    const [dismissedKeywords, setDismissedKeywords] = React.useState<string[]>([]);

    const options = React.useMemo(
      () =>
        values
          ? values.map((k) => {
              const isLoading =
                FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS_SIGNALS) &&
                feedPreviewLoading &&
                lastAddedKeyword === k;
              const keywordCount = keywordCounts?.[k];
              const warning = validateTerm(keywordCount, k);
              const formattedKeyword = formatKeywordTag(k, css.keywordNoEmphasis);
              const renderCountLabel = getCountLabel({
                count: keywordCount,
                value: formattedKeyword,
                warning,
                isLoading: isLoading,
              });
              return (
                <Select.Option
                  // Less than ideal hack with the title but a necessary hack to style the border of the keyword
                  // TODO: Ideally this component shouldn't even be using AutoClosingSelect, have to write custom component for this
                  title={(warning && "warning") || (isLoading && "loading")}
                  key={k}
                >
                  {renderCountLabel}
                </Select.Option>
              );
            })
          : [],
      [values, feedPreviewLoading, lastAddedKeyword, keywordCounts],
    );

    const handleOnChange = (newValues: string[]) => {
      const deletedKeywords = values?.filter((element) => !newValues.includes(element));
      deletedKeywords && setDismissedKeywords((prev) => [...prev, ...deletedKeywords]);
      // For any signals that have unclosed quotes, clean them up here
      const cleanedValues = newValues.map((value) => completeUnclosedQuotes(value));
      const deDuped = filterDuplicatesKeywords(cleanedValues);
      const lastAddedKeyword = deletedKeywords?.length === 0 ? deDuped[deDuped.length - 1] : null;
      setLastAddedKeyword(lastAddedKeyword);
      onChange(deDuped);
    };

    return (
      <div className={classnames(css.keywordsWrapper, className)} ref={ref}>
        {showSuggestedKeywords &&
          FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS_SIGNALS) && (
            <SuggestedKeywords
              description={
                "Add these keywords to your selection by pressing ‘+’. Our keywords are generated by AI based on your company's URL, so may not always be accurate."
              }
              onClick={(keyword: string) => {
                values?.push(keyword);

                if (values) handleOnChange(values);
              }}
              existingKeywords={values || []}
              dismissedKeywords={dismissedKeywords}
            />
          )}

        <div>
          {label && (
            <>
              <label className={css.keywordsLabel} htmlFor={randomizedID}>
                {label}
                {hintTooltip && (
                  <InfoButton
                    className={css.hintIcon}
                    note={hintTooltip}
                    tooltipOverlayStyle={{ minWidth: 400 }}
                  />
                )}
              </label>
              <p className={css.hintLabel}>
                {hintLabel ||
                  "Use a comma, semicolon, or press enter to separate keywords that you’d like to track."}
              </p>
            </>
          )}
          <AutoclosingMultiselect
            dropdownClassName={css.keywordsDropdown}
            notFoundContent={undefined}
            id={randomizedID}
            mode="tags"
            value={values}
            onChange={handleOnChange}
            tokenSeparators={[",", ";"]}
            size="large"
            className={css.select}
            allowClear={false}
            placeholder="Type keywords to get started..."
            disabled={disabled}
          >
            {options}
          </AutoclosingMultiselect>
        </div>
      </div>
    );
  },
);

export default KeywordsField;
