import { useRef } from "react";
import { App } from "antd5";

import { useHubspotIdToken } from "lib/hooks/api/hubspot/useHubspotIdToken";

export function useSupportChat(): { openSupportChat: (triggeredFrom?: string) => void } {
  const retryCount = useRef(0);
  const { message } = App.useApp();
  // Unfortunately, there's a weird error when accessing process.env here.
  // As such, we're using the slightly hackier method of checking the hostname.
  const isDevEnvironment = !window.location.hostname.includes("stotles");

  const user = window.currentUser;
  const { data, isLoading } = useHubspotIdToken({
    enabled: !isDevEnvironment,
  });

  function openHubspotChat() {
    if (!window.HubSpotConversations && retryCount.current < 5) {
      retryCount.current += 1;
      setTimeout(openHubspotChat, 100);
      return;
    }

    if (!window.HubSpotConversations && retryCount.current >= 5) {
      void message.error(
        "App support chat failed to load. Please contact us via email at cs@stotles.com instead, or try again later.",
      );
      return;
    }

    if (isDevEnvironment) {
      return;
    }
    if (!isLoading && !!data && !!user) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: user.email,
        identificationToken: data.token,
      };
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    } else {
      window.HubSpotConversations.widget.load({ widgetOpen: true });
    }
  }

  return { openSupportChat: openHubspotChat };
}
