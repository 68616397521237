import React from "react";
import { Control } from "react-hook-form";
import { Tag } from "antd5";

import { NoticeFilters } from "components/notices/utils";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { Radio } from "./Inputs";

import css from "./MentionsAwardsFilter.module.scss";

const multipleSuppliersText = {
  description: "Showing all notices where these suppliers are:",
  directAndMention:
    "Show notices where these suppliers are the awarded supplier or mentioned in the title or description.",
  directOnly: "Show notices where these suppliers are the awarded supplier.",
  mentionOnly: "Show notices where these suppliers are mentioned in the title or description",
};

const singleSupplierText = {
  description: "Showing all notices where this supplier is:",
  directAndMention:
    "Show notices where this supplier is the awarded supplier or mentioned in the title or description.",
  directOnly: "Show notices where this supplier is the awarded supplier.",
  mentionOnly: "Show notices where this supplier is mentioned in the title or description",
};

function Label({
  name,
  description,
  isDefault,
}: {
  name: string;
  description: string;
  isDefault?: boolean;
}) {
  return (
    <div className={css.optionContainer}>
      <div className={css.optionNameContainer}>
        <span className={css.optionName}>{name}</span>
        {isDefault && <Tag className={css.defaultTag}> Default</Tag>}
      </div>
      <span>{description}</span>
    </div>
  );
}

export function MentionsAwardsFilter({
  control,
  isSingleSupplier = false,
}: {
  control: Control<NoticeFilters, unknown>;
  isSingleSupplier?: boolean;
}) {
  const text = isSingleSupplier ? singleSupplierText : multipleSuppliersText;

  return (
    <Radio
      className={css.radioContainer}
      label={text.description}
      name={"supplierMentionType"}
      defaultValue={SupplierMentionType.MentionedOrDirectlyAwarded}
      control={control}
      options={[
        {
          label: (
            <Label
              name="Directly awarded & Mentioned"
              description={text.directAndMention}
              isDefault={true}
            />
          ),
          value: SupplierMentionType.MentionedOrDirectlyAwarded,
        },
        {
          label: <Label name="Directly awarded" description={text.directOnly} />,
          value: SupplierMentionType.DirectlyAwarded,
        },
        {
          label: <Label name="Mentioned in" description={text.mentionOnly} />,
          value: SupplierMentionType.MentionedInOnly,
        },
      ]}
      optionType="default"
      direction="vertical"
    />
  );
}
