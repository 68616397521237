import { captureException } from "@sentry/react";

import {
  ViewConfigurationFilterSettingsRelevanceScoreEnum as RelevanceScoreEnum,
  ViewConfigurationFilterSettingsSignals,
} from "lib/generated/app-api";
import { SignalDefinition } from "lib/generated/app-service-gql/graphql";
import { SignalSettings } from "lib/hooks/api/teams/useSignalSettingsGQL";
import { EventData } from "lib/tracking";
import {
  ALL_COMPETITORS_TOKEN,
  ALL_CPV_CODES_TOKEN,
  ALL_KEYWORDS_TOKEN,
  ALL_PARTNERS_TOKEN,
} from "lib/types/models";

export enum SignalEntityType {
  Framework = "FRAMEWORK",
  ProcurementNotice = "PROCUREMENT_NOTICE",
  Document = "DOCUMENT",
}
type SignalResponse = {
  allSignals: SignalDefinition[];
  keywordSignals: SignalDefinition[];
  competitorSignals: SignalDefinition[];
  partnerSignals: SignalDefinition[];
  cpvSignals: SignalDefinition[];
};

function checkIfStringIsGuid(string: string): boolean {
  const guidRegex = new RegExp(
    "^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$",
    "i",
  );
  return guidRegex.test(string);
}

export const SIGNAL_SCORE_MAP: Record<RelevanceScoreEnum, number> = {
  [RelevanceScoreEnum.High]: 3,
  [RelevanceScoreEnum.Medium]: 2,
  [RelevanceScoreEnum.Low]: 1,
  [RelevanceScoreEnum.None]: 0,
};

/**
 * This function is for backwards compatibility reasons, in case a user created a view with multiple relevance scores
 * @param relevanceScores
 * @returns
 */
export function getMinRelevanceScore(relevanceScores: RelevanceScoreEnum[]): number | undefined {
  if (relevanceScores.length === 0) {
    return undefined;
  }
  const minScore = Math.min(...relevanceScores.map((score) => SIGNAL_SCORE_MAP[score]));

  return minScore > 0 ? minScore : undefined;
}

export function mapSignalsToViewSignals(signals: string[]): ViewConfigurationFilterSettingsSignals {
  const viewSignals: ViewConfigurationFilterSettingsSignals = {
    ids: [],
    categories: [],
  };

  signals.forEach((signal) => {
    if (checkIfStringIsGuid(signal)) {
      viewSignals.ids?.push(signal);
    } else {
      viewSignals?.categories?.push(signal);
    }
  });

  return viewSignals;
}

/**
 * Simple util function which organizes signals by category
 * @param allSignals
 */
export function categorizeSignals(allSignals: SignalDefinition[]): SignalResponse {
  const keywordSignals: SignalDefinition[] = [];
  const competitorSignals: SignalDefinition[] = [];
  const partnerSignals: SignalDefinition[] = [];

  const cpvSignals: SignalDefinition[] = [];
  for (const signal of allSignals) {
    switch (signal.category) {
      case "Keywords":
        keywordSignals.push(signal);
        break;
      case "Competitors":
        competitorSignals.push(signal);
        break;
      case "Partners":
        partnerSignals.push(signal);
        break;
      case "CPV codes":
        cpvSignals.push(signal);
        break;
      default:
        captureException(`Unknown signal category: ${signal.category}`);
    }
  }

  return { allSignals, keywordSignals, competitorSignals, partnerSignals, cpvSignals };
}

export function handleSavedSignalTrackingData(
  data: EventData,
  key: string,
  value: string[],
  signalSettings: SignalSettings,
): EventData {
  const { keywords, competitors, partners, cpvCodes } = signalSettings;

  const supplierSignalGuids = (competitors || []).concat(partners || []).map((s) => s.id);

  if (key === "keywords" && !!keywords) {
    return addSavedSignalTrackingData(data, value, keywords, "Saved keyword?", ALL_KEYWORDS_TOKEN);
  }

  if (key === "cpvCodes" && !!cpvCodes) {
    return addSavedSignalTrackingData(
      data,
      value,
      cpvCodes,
      "Saved CPV code?",
      ALL_CPV_CODES_TOKEN,
    );
  }

  if (key === "supplierGuids" && supplierSignalGuids.length > 0) {
    data["Filter name"] = "Suppliers";
    if (value.length > 0) {
      data["Saved supplier?"] = isSavedSignalTracking(value, supplierSignalGuids);

      if (value.includes(ALL_COMPETITORS_TOKEN) && competitors) {
        value = value.filter((guid) => !competitors?.map((c) => c.id).includes(guid));
      }
      if (value.includes(ALL_PARTNERS_TOKEN) && partners) {
        value = value.filter((guid) => !partners?.map((p) => p.id).includes(guid));
      }

      data["Filter selection"] = value;
    }
  }

  return data;
}

function addSavedSignalTrackingData(
  data: EventData,
  values: string[],
  savedSignals: string[],
  savedSignalKey: string,
  savedSignalToken: string,
): EventData {
  if (!Array.isArray(values) || values.length === 0) {
    return data;
  }

  data[savedSignalKey] = isSavedSignalTracking(values, savedSignals);

  if (values.includes(savedSignalToken)) {
    values = values.filter((v) => !savedSignals.includes(v));
  }

  data["Filter selection"] = values;

  return data;
}

const isSavedSignalTracking = (
  selectedItems: string[],
  allSignalItems: string[],
): "Yes" | "No" | "Some" => {
  if (allSignalItems.every((item) => selectedItems.includes(item))) {
    return "Yes";
  }

  if (allSignalItems.some((item) => selectedItems.includes(item))) {
    return "Some";
  }

  return "No";
};
