import React from "react";
import { Control, Controller } from "react-hook-form";
import { InputNumber } from "antd5";

import { Checkbox } from "../../form_components/Inputs";
import { NoticeFilters } from "../utils";

import css from "./ContractValue.module.scss";

export interface ContractValueFilterContentProps {
  control: Control<NoticeFilters, unknown>;
  data: NoticeFilters;
}

function ContractValueFilterContent({ control, data }: ContractValueFilterContentProps) {
  return (
    <div className={css.inputContainer} aria-label="Value filters">
      <div className={css.filterSection}>
        <div>
          <div className={css.valueFields}>
            <Controller
              name="value.from"
              control={control}
              rules={{ min: 0 }}
              render={({ field, fieldState }) => (
                <InputNumber
                  {...field}
                  status={fieldState.error && "error"}
                  min={0}
                  placeholder="From"
                  className={css.valueInput}
                />
              )}
            />
            -
            <Controller
              name="value.to"
              control={control}
              rules={{ min: data.value.from || 0 }}
              render={({ field, fieldState }) => (
                <InputNumber
                  {...field}
                  status={fieldState.error && "error"}
                  min={0}
                  placeholder="To"
                  className={css.valueInput}
                />
              )}
            />
          </div>
          <Checkbox
            label=""
            name="value.hideNulls"
            control={control}
            fieldLabel="Hide notices that don't have a value"
          />
        </div>
      </div>
    </div>
  );
}

export default ContractValueFilterContent;
