import React from "react";
import { Card, Divider } from "antd5";
import classNames from "classnames";

import { CommentsContainer } from "components/comments/CommentsContainer";
import ContactCard from "components/form_components/ContactCard";
import AiRecordSummary from "components/record_summary/SummarySection";
import { RecordDto } from "lib/generated/app-api";
import { useRecordHighlighting } from "lib/hooks/useRecordHighlighting";
import { getAddress } from "lib/utils";
import { AutoQualifyCard } from "./AutoQualifyCard";
import CpvCodes from "./CpvCodes";
import DetailsCardContent from "./DetailsCardContent";
import LotDetails from "./LotDetails";
import NoticeTimeline from "./NoticeTimeline";
import WorkflowCard from "./WorkflowCard";

import css from "./RecordDetailsContent.module.scss";

type RecordDetailsContentProps = {
  record: RecordDto;
  trackingContextName: "Title header" | "Record preview slide-out";
  containerClassName?: string;
};

type DescriptionProps = {
  noticeGuid: string;
  noticeName: string;
  noticeDescription: string;
};

function Description({ noticeGuid, noticeName, noticeDescription }: DescriptionProps) {
  const { recordDescription } = useRecordHighlighting(noticeGuid, noticeName, noticeDescription);

  const description = noticeDescription ? recordDescription : "No description available.";

  return (
    <div className={css.description}>
      <h2 className={css.section}>Description</h2>
      {description}
    </div>
  );
}

export default function RecordDetailsContent({
  record,
  trackingContextName,
  containerClassName,
}: RecordDetailsContentProps) {
  const buyerAddress = getAddress(
    record.buyer.country,
    record.buyer.address ?? "",
    record.buyer.town ?? "",
    record.buyer.postalCode ?? "",
  );

  return (
    <div className={classNames(containerClassName, css.container)}>
      <div className={css.leftColumn}>
        {!!record.description && record.description.length >= 150 && (
          <AiRecordSummary recordGuid={record.guid} />
        )}
        <Description
          noticeGuid={record.guid}
          noticeName={record.name}
          noticeDescription={record.description ?? ""}
        />
        <Divider className={css.divider} />
        <NoticeTimeline
          procurementProcessId={record.procurementStage.recordFamilyId}
          stage={record.stage}
        />
        <Divider className={css.divider} />
        <LotDetails lots={record.lots ?? []} />
        <Divider className={css.divider} />
        <CpvCodes cpvCodes={record.cpvCodes} />
      </div>
      <div className={css.rightColumn}>
        {!window.guestUser && (
          <>
            <AutoQualifyCard noticeId={record.guid} />
            <WorkflowCard
              procurementStage={{
                id: record.procurementStage.id,
                stage: record.procurementStage.stage,
                qualification: record.procurementStage.procurementStageQualification?.qualification,
              }}
              notice={{
                guid: record.guid,
                name: record.name,
                buyers: record.buyers,
              }}
              assignee={record.assignee ? { ...record.assignee } : undefined}
              signals={record.signals}
              score={record.relevanceScore ?? 0}
              trackingContextName={trackingContextName}
            />
          </>
        )}
        <Card className={css.card}>
          <div className={css.cardContent}>
            <h2 className={css.mediumTitle}>Details</h2>
            <DetailsCardContent
              notice={{
                guid: record.guid,
                stage: record.stage,
                value: record.valueSrc,
                currency: record.currency ?? "",
                url: record.url,
              }}
              buyers={record.buyers}
              lots={record.lots ?? []}
              signals={record.signals}
              score={record.relevanceScore ?? 0}
            />
          </div>
        </Card>
        <ContactCard
          name={record.buyerContactName ?? ""}
          phone={record.buyerContactPhone ?? ""}
          email={record.buyerContactEmail ?? ""}
          address={buyerAddress}
          website={record.buyer.url ?? ""}
          buyerIds={record.buyers.map((buyer) => buyer.guid)}
          showWebsite
          showContactsButton
          className={css.contactLabel}
        />
        {!window.guestUser && (
          <Card className={css.card}>
            <div className={css.cardContent}>
              <h2 className={css.smallTitle}>Comments</h2>
              <CommentsContainer
                procurementStageId={record.procurementStage.id}
                recordGuid={record.guid}
                containerClassName={css.commentsContainer}
              />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}
