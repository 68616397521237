import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, Input, Select } from "antd"; // button should probably be textbutton

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { DateRange } from "components/form_components/Inputs";
import { NewSupplierSelect } from "components/form_components/NewSupplierSelect";
import { createUseDebounce } from "lib/debounce";
import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { SignalDto } from "lib/generated/app-api/models";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { FILTER_COLLAPSE_MATCH_STYLING } from "../../lib/featureFlags";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";
import { SpendDataFilters as Filters } from "./hooks/useSpendPageState";

import css from "./SpendDataFilters.module.scss";

function getAllFromSignals(category: "Partners" | "Competitors", signals?: SignalDto[]) {
  const categorySignals = signals?.filter((signal) => signal.category === category) || [];
  return categorySignals.map((signal) => signal.name);
}

function SpendDataFilters({
  onClose,
  setFilters: handleChange,
  filters,
}: {
  onClose: () => void;
  filters: Filters;
  setFilters: (newFilters: Filters) => void;
}) {
  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_MATCH_STYLING, false);

  const { data: signalSettings } = useSignalSettings();

  const previousFilters = useRef(filters);

  const { control, watch, handleSubmit, setValue, reset } = useForm<Filters>({
    defaultValues: filters,
    mode: "onChange",
  });

  const data = watch();

  // submit form onChange
  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        previousFilters.current = d;
        handleChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleChange, handleSubmit, watch]);

  // Update form values when filters prop changes in parent
  useEffect(() => {
    Object.keys(filters).forEach((key) => {
      setValue(key as keyof Filters, filters[key as keyof Filters]);
    });
  }, [filters, setValue]);

  const allCompetitors = getAllFromSignals("Competitors", signalSettings?.signals);
  const allPartners = getAllFromSignals("Partners", signalSettings?.signals);
  const [supplierName, setSupplierName] = React.useState("");
  const useDebounce400 = createUseDebounce(400);

  const handleSupplierNameTextSearch = useDebounce400((text: string) => {
    setValue("supplier", text);
  });

  return (
    <form aria-label="Spend filters">
      {isCollapseEnabled && <FilterFormTitle onClose={onClose} />}
      <div className={isCollapseEnabled ? css.formContent : undefined}>
        {!isCollapseEnabled && (
          <header className={css.header}>
            <h2>Filter spending</h2>{" "}
            <Button type="link" onClick={() => reset()}>
              Clear all
            </Button>
          </header>
        )}

        <div className={css.filterGroup}>
          <h3>Transaction details</h3>
          <div className={css.filterInput}>
            <DateRange name="dateRange" control={control} label="Transaction date" />
          </div>
        </div>

        <Divider />

        <div className={css.filterGroup}>
          <h3>Buyer</h3>
          <div className={css.filterInput}>
            <BuyerSelect
              name="buyers"
              label="Buyers"
              control={control}
              mode="multiple"
              placeholder="Search buyers..."
              disabled={data.buyerLists && data.buyerLists.length > 0}
              allowClear
            />
          </div>
          <div className={css.filterInput}>
            <SelectBuyerList
              name="buyerLists"
              label="My buyer lists"
              control={control}
              placeholder="Select buyer list"
              mode="multiple"
              disabled={data.buyers && data.buyers.length > 0}
              allowClear
            />
          </div>
          <div className={css.filterInput}>
            <BuyerTypeSelect
              name="buyerCategories"
              control={control}
              multiple
              label="Buyer types"
              placeholder="Select buyer types"
              allowClear
            />
          </div>
        </div>

        <Divider />

        <div className={css.filterGroup}>
          <h3>Supplier</h3>
          {enableNewSupplierFilter && window.currentUser?.use_supplier_name === false ? (
            <div className={css.filterInput}>
              <NewSupplierSelect name="supplierGuids" label="Supplier Guids" control={control} />
            </div>
          ) : (
            <>
              <div className={css.filterInput}>
                <span>Supplier name</span>
                <Input
                  type="string"
                  onChange={(val) => {
                    setSupplierName(val.target.value);
                    handleSupplierNameTextSearch(val.target.value);
                  }}
                  placeholder="Search supplier name"
                  value={supplierName}
                />
              </div>
              <div className={css.filterInput}>
                <span>My competitors</span>
                <Select
                  value={filters.competitors === "ALL" ? undefined : filters.competitors}
                  onChange={(val: string[]) => setValue("competitors", val)}
                  mode="multiple"
                  showSearch
                  allowClear
                  showArrow
                  placeholder="Select competitors"
                >
                  {allCompetitors.map((name) => (
                    <Select.Option value={name} key={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className={css.filterInput}>
                <span>My partners</span>
                <Select
                  value={filters.partners === "ALL" ? [] : filters.partners}
                  onChange={(val: string[]) => setValue("partners", val)}
                  mode="multiple"
                  showSearch
                  allowClear
                  showArrow
                  placeholder="Select partners"
                >
                  {allPartners.map((name) => (
                    <Select.Option value={name} key={name}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
}

export default SpendDataFilters;
