import * as React from "react";
import moment from "moment";

import { DataTypeName, DataTypeNameWithBuyersAndSuppliers } from "lib/types/models";

export const availableDataTypes: DataTypeName[] = [
  "TENDERS|PRE-TENDERS",
  "AWARDS",
  "BUYERS|SUPPLIERS",
  "CONTACTS",
  "FRAMEWORKS",
  "DOCUMENTS",
];

interface SubscriptionProvider {
  activatedDataTypes: DataTypeName[];
  deactivatedDataTypes: DataTypeName[];
  hasDataTypes: (...dataTypes: DataTypeNameWithBuyersAndSuppliers[]) => boolean;
  hasSubscribedDataTypes: (...dataTypes: DataTypeNameWithBuyersAndSuppliers[]) => boolean;
  getMissingDataTypes: (
    ...dataTypes: DataTypeNameWithBuyersAndSuppliers[]
  ) => DataTypeNameWithBuyersAndSuppliers[];
  trialEndDate: Date | null;
  trialStartDate: Date | null;
  companyPaymentType: string | null;
  eligibleForTrial: () => boolean;
  onTrial: () => boolean;
}

const toDataTypeName = (t: DataTypeNameWithBuyersAndSuppliers): DataTypeName =>
  t === "BUYERS" || t === "SUPPLIERS" ? "BUYERS|SUPPLIERS" : t;

export class DefaultSubscriptionProvider implements SubscriptionProvider {
  public readonly deactivatedDataTypes: DataTypeName[];
  public readonly activatedDataTypes: DataTypeName[];
  public readonly subscribedDataTypes: DataTypeName[];
  public readonly trialEndDate: Date | null;
  constructor(
    // cannot use `public readonly` sugar here because activatedDataTypes is used in the constructor in
    // this.getMissingDataTypes - and it ends up undefined in some instances (eg storybook)
    activatedDataTypes: DataTypeName[],
    subscribedDataTypes: DataTypeName[],
    public readonly trialStartDate: Date | null,
    trialEndDate: Date | null,
    public readonly companyPaymentType: string | null,
  ) {
    sortDataTypes(activatedDataTypes);
    sortDataTypes(subscribedDataTypes);
    this.activatedDataTypes = activatedDataTypes;
    this.subscribedDataTypes = subscribedDataTypes;
    this.deactivatedDataTypes = this.getMissingDataTypes(...availableDataTypes) as DataTypeName[];
    this.trialEndDate = trialEndDate;
  }

  // Returns true if the user has access to all the provided data types or if they're a guest user
  // Does not apply to CONTACTS as it's sensitive data
  public hasDataTypes = (...dataTypes: DataTypeNameWithBuyersAndSuppliers[]): boolean => {
    if (dataTypes.includes("CONTACTS")) {
      return !this.getMissingDataTypes(...dataTypes).length;
    } else return !!window.guestUser || !this.getMissingDataTypes(...dataTypes).length;
  };

  // This method checks for paid for data types (if on trial for example, they're not paid for)
  public hasSubscribedDataTypes = (...dataTypes: DataTypeNameWithBuyersAndSuppliers[]): boolean => {
    return dataTypes.every((type) => this.subscribedDataTypes.includes(toDataTypeName(type)));
  };

  public getMissingDataTypes = (
    ...dataTypes: DataTypeNameWithBuyersAndSuppliers[]
  ): DataTypeNameWithBuyersAndSuppliers[] => {
    const activeDataTypes = this.activatedDataTypes;
    return dataTypes.filter((type) => activeDataTypes.indexOf(toDataTypeName(type)) === -1);
  };

  eligibleForTrial = (): boolean => {
    return !this.trialEndDate && this.companyPaymentType !== "Paid";
  };

  onTrial = (): boolean => {
    if (!this.trialEndDate) return false;
    const endOfTrialTime = moment.utc(this.trialEndDate).endOf("day");
    const now = moment.utc();
    return now <= endOfTrialTime;
  };

  trialStatus = (): "On Trial" | "Eligible" | "Ineligible" => {
    if (this.onTrial()) {
      return "On Trial";
    } else if (this.eligibleForTrial()) {
      return "Eligible";
    } else {
      return "Ineligible";
    }
  };
}

function getActiveDataTypesFromWindow() {
  return window.currentUser?.active_data_types ?? [];
}

function getSubscribedDataTypesFromWindow() {
  return window.currentUser?.subscribed_data_types ?? [];
}

function getDateFromWindowCompany(date: "trial_start" | "trial_end") {
  const dateString = window.currentUser?.company[date];
  return dateString ? new Date(dateString) : null;
}

function getCompanyPaymentTypeFromWindow() {
  return (window.currentUser?.company.analytics_tags?.company_payment_type as string) ?? null;
}

function sortDataTypes(dataTypes: DataTypeName[]) {
  dataTypes.sort((a, b) => availableDataTypes.indexOf(a) - availableDataTypes.indexOf(b));
}

export const getWindowSubscriptionProvider = (): DefaultSubscriptionProvider =>
  new DefaultSubscriptionProvider(
    getActiveDataTypesFromWindow(),
    getSubscribedDataTypesFromWindow(),
    getDateFromWindowCompany("trial_start"),
    getDateFromWindowCompany("trial_end"),
    getCompanyPaymentTypeFromWindow(),
  );

export const SubscriptionContext = React.createContext<SubscriptionProvider>(
  getWindowSubscriptionProvider(),
);

export function useSubscription(): SubscriptionProvider {
  return React.useContext(SubscriptionContext);
}
