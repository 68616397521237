import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { GraphQLError } from "graphql";

import { graphql } from "lib/generated/app-service-gql";
import {
  NoticeDocsQuery,
  ProcessNoticeDocumentMutation,
  ProcessNoticeDocumentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const processNoticeDoc = graphql(`
  mutation ProcessNoticeDocument($input: ProcessNoticeDocumentRequest!) {
    processNoticeDocument(ProcessNoticeDocumentRequest: $input) {
      success
    }
  }
`);

export function useProcessNoticeDocument(
  options?: UseMutationOptions<
    ProcessNoticeDocumentMutation,
    GraphQLError,
    ProcessNoticeDocumentMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(processNoticeDoc, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      queryClient.setQueryData(
        ["noticeDocuments", vars.input.noticeId],
        (oldData: NoticeDocsQuery | undefined) => {
          return {
            __typename: "Query" as const,
            noticeDocuments: {
              __typename: "NoticeDocumentsResponse" as const,
              documents: [
                ...(oldData?.noticeDocuments.documents || []),
                {
                  __typename: "NoticeDocumentsResponse_Documents" as const,
                  id: vars.input.documentId,
                  name: vars.input.documentName,
                  noticeId: vars.input.noticeId,
                },
              ],
            },
          };
        },
      );
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.processNoticeDocument, ...rest };
}
