import React from "react";
import { Control, Controller } from "react-hook-form";

import { NEW_NOTICES_SEARCH, useVariableValue } from "lib/featureFlags";
import { NewRelevanceScoreButtons } from "../../form_components/NewRelevanceScoreButtons";
import { RelevanceScoreButtons } from "../../form_components/RelevanceScoreButtons";
import { SignalSelect } from "../../form_components/SignalSelect";
import { NoticeFilters } from "../utils";

import css from "./Signals.module.scss";

export interface SignalsFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function SignalsFilterContent({ control }: SignalsFilterContentProps) {
  const useNewNoticesSearch = useVariableValue(NEW_NOTICES_SEARCH, false);
  return (
    <div className={css.filterSection} aria-label="Signals filters">
      <SignalSelect
        name="signals"
        label="Signals"
        control={control}
        placeholder="Select..."
        allowClear
        hideLabel
      />
      <Controller
        name="relevanceScore"
        control={control}
        render={({ field }) => (
          <div className={css.inputContainer}>
            <label htmlFor="relevanceScore" className={css.inputLabel}>
              <h3>Signal score</h3>
            </label>
            {useNewNoticesSearch ? (
              <NewRelevanceScoreButtons {...field} entityType="notices" />
            ) : (
              <RelevanceScoreButtons {...field} checkedRelevanceScores={field.value} />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default SignalsFilterContent;
