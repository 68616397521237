import React from "react";
import { Checkbox, Divider, Empty } from "antd5";
import { CheckboxProps } from "antd5/lib";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { CpvCode, deselectNode } from "./cpv_code_selects/cpv_code_utils";

import css from "./ClassificationListSelect.module.scss";

export type Option = { label: string; value: string; icon?: React.ReactNode };

type Props = {
  classificationName: React.ReactNode | string;
  options: Option[];
  value: string[];
  onChange: (newValue: string[]) => void;
  isChecked: (option: Option) => boolean;
  allCpvCodes: CpvCode[];
  emptyState?: JSX.Element;
};

function ClassificationListSelect({
  classificationName,
  options,
  value,
  onChange,
  isChecked,
  allCpvCodes,
  emptyState,
}: Props) {
  const checkedList = value ? options.filter((o) => value.includes(o.value)) : [];
  const isAllChecked = options.length === checkedList.length;
  const isSomeChecked = checkedList.length > 0 && checkedList.length < options.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    const optionValues = options.map((o) => o.value);
    if (e.target.checked) {
      onChange(Array.from(new Set([...value, ...optionValues])));
    } else {
      onChange(value.filter((v) => !optionValues.includes(v)));
    }
  };

  if (options.length === 0) {
    return emptyState ?? <Empty className={css.emptyState} />;
  }

  return (
    <div id="scrollableDiv" className={css.scrollableDiv} aria-label="scrollableDiv">
      <div className={css.optionsHeader}>
        {options.length > 0 && (
          <label className={css.labelAndIcon}>
            <Checkbox
              aria-label="Select all"
              indeterminate={isSomeChecked}
              onChange={onCheckAllChange}
              checked={isAllChecked}
              className={css.label}
            >
              Select all {classificationName}
            </Checkbox>
          </label>
        )}
        <Divider className={css.divider} />
      </div>

      <div className={css.checkboxGroup}>
        {options.map((option) => (
          <React.Fragment key={option.value}>
            <label className={css.labelAndIcon}>
              <Checkbox
                key={option.value}
                value={option.value}
                name={option.label}
                checked={isChecked(option)}
                className={css.label}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    return onChange([...value, option.value]);
                  }
                  // it must be a parent node so we can just remove it
                  if (value.includes(option.value)) {
                    return onChange(value.filter((v) => v !== option.value));
                  }
                  // it is a deselected child node
                  // we need to traverse the tree removing all of it's parent nodes and adding the parents other children
                  const newValue = deselectNode(allCpvCodes, value, option.value);
                  onChange(newValue);
                }}
                aria-label="Select option"
              >
                <EllipsisTooltipText fullText={option.label} containerClassname={css.tag} />
              </Checkbox>
              {option.icon ?? null}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default ClassificationListSelect;
