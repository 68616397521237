import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd5";
import { useFormikContext } from "formik";

import { FormikAdminTextField } from "components/admin_forms/AdminTextField";
import { ReferenceOrgDto } from "lib/generated/app-api";
import { useDialogManager } from "lib/providers/DialogManager";
import OscarSearchWidget from "./OscarSearchWidget";

type OscarSearchModalProps = {
  handleChange: (referenceOrg?: ReferenceOrgDto) => void;
  isOpen: boolean;
  onClose: () => void;
};

export function OscarSearchModal({
  handleChange,
  isOpen,
  onClose,
}: OscarSearchModalProps): JSX.Element {
  return (
    <Modal
      style={{ top: "5vh", minWidth: "1000px" }}
      bodyStyle={{ height: "90vh" }}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <OscarSearchWidget
        onSelect={(b) => {
          handleChange(b);
          onClose();
        }}
      />
    </Modal>
  );
}

function OscarFormField<FormType>(): JSX.Element {
  const { setFieldValue } = useFormikContext<FormType>();
  const dialogManager = useDialogManager();

  const handleOpenOscarWidget = React.useCallback(() => {
    void dialogManager.openDialog(OscarSearchModal, {
      handleChange: (o) => setFieldValue("oscarId", o?.sourceIdentifier),
    });
  }, [dialogManager, setFieldValue]);
  return (
    <div style={{ display: "grid", gridTemplateColumns: "auto min-content", alignItems: "center" }}>
      <FormikAdminTextField name="oscarId" field="Oscar ID" />
      {/* Due to all the margins built into the formik admin text field we have adjust the icon awkardly */}
      <Button onClick={handleOpenOscarWidget} style={{ marginTop: "4px" }}>
        <SearchOutlined />
      </Button>
    </div>
  );
}

export default OscarFormField;
