import * as React from "react";
import { hot } from "react-hot-loader/root";
import { Card, Col, message, Row, Typography } from "antd"; // remove typography upgrade to new context mmessage

import { TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import SignUpForm, { SignupFormDataNoPasswords } from "components/onboarding/SignUpForm";
import { useStotlesApi, withStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { PartnerProgramme, UserInvitation } from "lib/types/models";
import { useSupportChat } from "lib/utils/useSupportChat";

import css from "./SignUpPage.module.scss";

import LogoURL from "../../../assets/images/stotles_logo_blue.svg";

type Props = {
  invitation: UserInvitation | null;
  partner_programme: PartnerProgramme | null;
};

function getInitialFormData(invitation: UserInvitation | null): SignupFormDataNoPasswords {
  let email: string | null;
  let firstName: string | null;
  let lastName: string | null;
  let companyName: string | null;
  let leadNotes: string | null = null;
  let campaignDescription: string | null = null;

  if (invitation) {
    const details = invitation.invitee_details;
    email = details.email;
    firstName = details.first_name;
    lastName = details.last_name;
    companyName = invitation.target_company?.name || invitation.new_company_name || null;
  } else {
    const urlParams = new URLSearchParams(window.location.search.substr(1));
    email = urlParams.get("email");
    firstName = urlParams.get("first_name");
    lastName = urlParams.get("last_name");
    companyName = urlParams.get("company");
    leadNotes = urlParams.get("lead_notes");
    campaignDescription = urlParams.get("campaign_descr");
  }

  return {
    firstName: firstName || "",
    lastName: lastName || "",
    email: email || "",
    company: companyName || "",
    leadNotes: leadNotes || "",
    campaignDescription: campaignDescription || "",
  };
}

function SignUpPage({ invitation, partner_programme }: Props) {
  const { openSupportChat } = useSupportChat();

  const api = useStotlesApi();
  const [formData, setFormData] = React.useState(() => getInitialFormData(invitation));
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (formData.email) tracking.identifyByEmail(formData.email);
    const payload: Record<string, any> = {
      email: formData.email,
      "first name": formData.firstName,
      "last name": formData.lastName,
      "company name": formData.company,
    };
    if (invitation) {
      payload["invitation sent at"] = invitation.created_at;
      payload["invitation sent by"] = invitation.inviter_id;
    }
    tracking.pageView("Onboarding - User Info Form", payload);

    // This effect is a pageView only effect - ie. its responsibility is purely to track pageView
    // events, and log a payload from when the user first viewed the page. When the formData
    // changes, another pageView event should not be triggered
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = React.useMemo(() => {
    return (
      <Col xs={20} sm={16} md={12} lg={10} xl={8} xxl={6}>
        <Card bordered={false} className={css.formCard}>
          <img className={css.topnavLogo} width={150} src={LogoURL} />
          <Typography.Title className={css.subHeader}>
            Create an account to get started with Stotles
          </Typography.Title>
          <br />
          <SignUpForm
            initialValues={formData}
            hasInvitation={Boolean(invitation)}
            // target_company exists => user being assigned to existing company => user cannot
            // change it
            companyInputDisabled={!!invitation?.target_company}
            formSubmitting={formSubmitting}
            onSubmit={async (fD) => {
              setFormSubmitting(true);
              // if unable to identify earlier in the useEffect because email was not filled,
              // but the user is able to be identified now
              if (!formData.email && fD.email) tracking.identifyByEmail(fD.email);

              setFormData(fD);
              try {
                const resp = await api.createUser(fD, partner_programme, invitation?.id);

                tracking.logEvent(tracking.EventNames.userAccountCreated, {
                  Email: resp.user.email,
                  "First name": fD.firstName,
                  "Last name": fD.lastName,
                  "Company name": resp.user.company.name,
                  "Unlocked data sources": resp.user.data_type_subscriptions.map(
                    (s) => s.data_type_name,
                  ),
                  "Partner Programme Name": partner_programme?.name,
                  "Partner Programme Code": partner_programme?.referral_code,
                });

                await tracking.logUserConversion();

                if (!resp.user.is_verified) {
                  window.location.href = `/email-verification`;
                } else if (invitation?.target_company) {
                  window.location.href = "/";
                } else {
                  window.location.href = `/onboarding`;
                }
              } catch (e) {
                message.warning(
                  "It looks like we encountered a problem. Please reach out to team@stotles.com if it persists.",
                  5,
                );
                throw e;
              } finally {
                setFormSubmitting(false);
              }
            }}
          />
          <p className={css.signIn}>
            Already have an account?{" "}
            <TextLink to="/users/sign_in">
              <b>Sign in</b>
            </TextLink>
          </p>
        </Card>
      </Col>
    );
  }, [api, formData, invitation, partner_programme, formSubmitting]);

  return (
    <div className={css.contentsWrapper}>
      <Row className={css.mainRow} type="flex" align="middle" justify="space-around">
        {content}
      </Row>
      <footer className={css.pageFooter}>
        <div>
          <p>
            If your company is already working with Stotles, get your free account by{" "}
            <a onClick={() => openSupportChat("Sign Up Page")}>chatting with us</a> or emailing us
            at <TextLink to="mailto:team@stotles.com">team@stotles.com</TextLink>.
          </p>
          <p>
            By clicking 'Continue' you agree to{" "}
            <a href="https://www.notion.so/stotles/Stotles-Terms-Conditions-c06c71f5ca19484c8a18d14fd6eadae5">
              Stotles' Terms and Conditions
            </a>{" "}
            and{" "}
            <a href="https://www.notion.so/stotles/Stotles-Privacy-Policy-4a597d71636d443c9be325fef2382701">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </footer>
    </div>
  );
}

export default withAppLayout(withStotlesApi(hot(SignUpPage)), {
  hideMenuItems: true,
  hideUserItems: true,
});
