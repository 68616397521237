import * as React from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { ButtonLink } from "components/actions/Links";
import Signal from "lib/icons/Signal";
import { useSupportChat } from "lib/utils/useSupportChat";

import css from "./EmptyNotices.module.scss";

interface Props {
  setIsFilterDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EmptyNotices({ setIsFilterDrawerOpen }: Props): JSX.Element {
  const { openSupportChat } = useSupportChat();
  return (
    <div className={css.emptyNoticesWrapper}>
      <div className={css.emptyNoticesHeader}>
        <h1 className={css.title}> No results found</h1>
        <p>Change your settings below to get back on track.</p>
      </div>

      <div className={css.emptyNoticesContent}>
        <div className={css.emptyNoticesCard}>
          <div className={css.emptyNoticesCardContent}>
            <h3>Edit your current filters</h3>
            <p>Try making some changes to your filters to widen your current search.</p>
          </div>

          <Button onClick={() => setIsFilterDrawerOpen(true)}>
            <FilterOutlined className={css.filterIcon} />
            Filters
          </Button>
        </div>

        <div className={css.emptyNoticesCard}>
          <div className={css.emptyNoticesCardContent}>
            <h3>Add to your signal settings</h3>
            <p>Add more signals to widen your overall view of the public sector on Stotles.</p>
          </div>

          <ButtonLink
            to={"/account-management/feed-settings"}
            icon={<Signal className={css.signalIcon} label="signalSettingsIcon" />}
          >
            Signal settings
          </ButtonLink>
        </div>

        <div className={css.contactUsSection}>
          Still having trouble?
          <Button
            type="link"
            onClick={() => {
              openSupportChat("My Feed - No results");
            }}
          >
            Speak to support
          </Button>
        </div>
      </div>
    </div>
  );
}
