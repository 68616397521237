import { EventDataTypes, EventNames, Tracking, useTracking } from "lib/tracking";

const trackingEvents = (tracking: Tracking) => ({
  documentSearched: () =>
    tracking.logEvent(EventNames.documentSearched, {
      "Input name": "Keyword",
    }),
  documentSourceViewed: () => tracking.logEvent(EventNames.documentSourceViewed),
  exportedData: () =>
    tracking.logEvent(EventNames.exportedData, {
      "Data type": EventDataTypes.documents,
    }),
});

export function useDocumentTracking() {
  const tracking = useTracking();
  return trackingEvents(tracking);
}
