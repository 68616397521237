import { isEqual, pickBy } from "lodash";

import { EventDataTypes, EventNames, Tracking, useTracking } from "lib/tracking";
import { isEmptyDeep } from "lib/utils";
import { DocumentFilters, filterKeyToLabel } from "./utils";

const trackingEvents = (tracking: Tracking) => ({
  filterDrawerOpened: () =>
    tracking.logEvent(EventNames.filterPanelOpened, {
      "Context source": "Filters button",
      "Data type": EventDataTypes.documents,
    }),
  documentPreviewOpened: (documentType: string) =>
    tracking.logEvent(EventNames.documentPreviewOpened, {
      "Document type": documentType,
    }),

  filterFormChanged: (previous: DocumentFilters, current: DocumentFilters) => {
    const diff = pickBy(current, (value, key) => {
      const previousVal = previous[key as keyof DocumentFilters];
      // If this check isn't present, the tracking code is triggered for empty filters also
      if (isEmptyDeep(previousVal) && isEmptyDeep(value)) return false;
      return !isEqual(previous[key as keyof DocumentFilters], value);
    });

    const events = Object.entries(diff).map(([key, value]) => ({
      name: EventNames.filterActioned,
      data: {
        "Context source": "Documents filter slide-out",
        "Filter name": filterKeyToLabel[key as keyof DocumentFilters],
        "Filter selection": value,
        "Action type":
          Array.isArray(value) && value.length === 0 ? "Filter cleared" : "Filter actioned",
        "Data type": EventDataTypes.documents,
      },
    }));

    tracking.logEvents(...events);
  },
});

export function useDocumentsTracking() {
  const tracking = useTracking();
  return trackingEvents(tracking);
}
