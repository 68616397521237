import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { hot } from "react-hot-loader/root";
import { CloseCircleOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, message, Skeleton } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import {
  ALL_NOTICE_COLUMNS,
  useMyFeedPageState,
  useViewUrlCheck,
} from "components/my_feed/useMyFeedPageState";
import ViewsContainer from "components/my_feed/views_bar/ViewsContainer";
import { EmptyNotices } from "components/notices/EmptyNotices";
import { ExportNoticesButton } from "components/notices/ExportNoticesButton";
import { FilterForm, groupedKeys } from "components/notices/FilterForm";
import { NoticeTable } from "components/notices/NoticeTable";
import { QuickFilterBar } from "components/notices/QuickFilterBar";
import { NoticeFilters } from "components/notices/utils";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { FeatureType } from "components/paywall/paywallUtils";
import { ClearSelectedButton } from "lib/core_components/ClearSelectedButton";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import {
  FILTER_COLLAPSE_NOTICES,
  LAYER_CAKE_FILTERS,
  NEW_NOTICES_SEARCH,
  NOTICE_FREE_TEXT_SEARCH,
} from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { ColumnSettingFieldEnum } from "lib/generated/app-api";
import { useSubscription } from "lib/providers/Subscription";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import FilterDrawer from "../../components/filter_form/filter_drawer/FilterDrawer";
import { Input } from "../../components/form_components/Inputs";
import { NewNoticeTable } from "../../components/notices/NewNoticeTable";
import { findKeybyValue } from "../../lib/utils";

import css from "./MyFeedPage.module.scss";

import MAGNIFYING_GLASS from "../../../assets/images/icons/magnifying_glass_detailed.svg";

enum ViewName {
  Competitor = "Competitor activity",
  Partner = "Partner activity",
  Expiries = "Upcoming contract expiries",
  // Still in app, legacy view
  CompetitorPartner = "Competitor & partner activity",
}

function getAppliedFilterCount(filters: NoticeFilters) {
  return Object.values(filters).reduce<number>((acc, val) => {
    if (Array.isArray(val)) {
      return acc + (val.length > 0 ? 1 : 0);
    }
    if (typeof val === "object") {
      // showed only show if either countries or regions has values (BuyerCountryRegions)
      if (
        ("countries" in val && val.countries.length > 0) ||
        ("regions" in val && val.regions.length > 0)
      ) {
        return acc + 1;
      }
      // is it a date filter with a filter object
      if ("hideNulls" in val && "filter" in val) {
        // check if the filter object has any values or if hideNulls is true
        const shouldAdd =
          Object.values(val.filter || {}).filter((v) => v).length > 0 || val.hideNulls;
        return acc + (shouldAdd ? 1 : 0);
      }
      // is it a date filter without a filter object
      if ("hideNulls" in val || "to" in val || "from" in val) {
        const shouldAdd = Object.values(val).filter((v) => v).length > 0;
        return acc + (shouldAdd ? 1 : 0);
      }

      // is it a sort object so dont count it
      if ("order" in val && "field" in val) {
        return acc;
      }
      return acc + (Object.keys(val).length > 0 ? 1 : 0);
    }
    if (typeof val === "string") {
      return acc + (val.length > 0 ? 1 : 0);
    }
    if (typeof val === "boolean") {
      return acc + 1;
    }
    return acc + (val ? 1 : 0);
  }, 0);
}

const useDebounce = createUseDebounce(300);

export function MyFeedPage() {
  const isLayerCakeEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  const {
    filters,
    setFilters,
    selectedView,
    clearViewAndFilters,
    isLoadingViews,
    tableColumns,
    setTableColumns,
  } = useMyFeedPageState(isLayerCakeEnabled);
  const [messageApi, messageContext] = message.useMessage();
  useViewUrlCheck(messageApi);

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_NOTICES, false);

  const [activeGroups, setActiveGroups] = useState<string[]>([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedNotices, setSelectedNotices] = useState<string[]>();

  const subscription = useSubscription();

  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  const hasFrameworks =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  const appliedFilters = useMemo((): NoticeFilters => {
    if (!isLayerCakeFiltersEnabled) {
      return filters;
    } else {
      //if layer cake is enabled, we need to override applied filters
      // only set supplierMentionType if supplierGuids are set
      filters.supplierMentionType =
        filters.supplierGuids && filters.supplierGuids.length > 0
          ? filters.supplierMentionType
          : undefined;

      //TODO: cleanup when layer cake is fully rolled out
      // clear sector filter as this is replaced by cpvCodes
      filters.cpvDimensions = [];
      // clear signals filter as this is removed
      filters.signals = [];

      return filters;
    }
  }, [filters, isLayerCakeFiltersEnabled]);

  const paywallFeature = React.useMemo((): FeatureType | undefined => {
    if (selectedView?.isStandardView) {
      switch (selectedView.name) {
        case ViewName.Expiries:
          return subscription.hasDataTypes("AWARDS") ? undefined : "AWARDS";

        case ViewName.Competitor:
          return subscription.hasDataTypes("BUYERS|SUPPLIERS") ? undefined : "COMPETITORS";
        case ViewName.Partner:
          return subscription.hasDataTypes("BUYERS|SUPPLIERS") ? undefined : "PARTNERS";
        case ViewName.CompetitorPartner:
          return subscription.hasDataTypes("BUYERS|SUPPLIERS") ? undefined : "SUPPLIERS";

        default:
          return undefined;
      }
    }
    return undefined;
  }, [selectedView?.isStandardView, selectedView?.name, subscription]);

  const filterCount = getAppliedFilterCount(appliedFilters);

  const { logEvent } = useTracking();

  const debouncedSetFilters = useDebounce(setFilters);

  const debounceTextSearch = useDebounce((text: string) => {
    logEvent(EventNames.freeSearch, {
      "Filter name": "Free search",
      "Action type": "Filter applied",
      "Data type": EventDataTypes.record,
    });
    setFilters({ ...appliedFilters, text });
  });

  const { control, handleSubmit, watch } = useForm<{ text: string }>({
    defaultValues: {
      text: appliedFilters.text,
    },
    mode: "onChange",
    values: appliedFilters,
  });

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit(({ text }) => {
        debounceTextSearch(text);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, debounceTextSearch, filters]);

  const isFreeTextSearchEnabled = useVariableValue(NOTICE_FREE_TEXT_SEARCH, false);

  return (
    <DetailsPage>
      <DetailsHeader borderBottom={!paywallFeature}>
        <ViewsContainer />
      </DetailsHeader>
      <>
        {paywallFeature ? (
          <PaywallBanner
            featureType={paywallFeature}
            className={paywallFeature === "AWARDS" ? undefined : css.fullHeightBanner}
          />
        ) : (
          <DetailsContent className={css.content}>
            {isLoadingViews ? (
              <>
                <Skeleton.Input active />
                <Skeleton active />
              </>
            ) : (
              <>
                <div className={css.filterBar}>
                  <div className={css.filterItems}>
                    {isFreeTextSearchEnabled && (
                      <form onSubmit={(e) => e.preventDefault()}>
                        <Input
                          placeholder="Search notices"
                          name="text"
                          control={control}
                          prefix={<SearchOutlined />}
                          label=""
                          allowClear
                          className={css.searchInput}
                        />
                      </form>
                    )}
                    <Button
                      onClick={() => setIsFilterDrawerOpen(true)}
                      icon={<FilterOutlined className={css.filterIcon} />}
                    >
                      Filters
                    </Button>
                  </div>
                  {filterCount > 0 && (
                    <div className={css.actionContainer}>
                      <div className={css.clearSelected}>
                        {selectedNotices && selectedNotices.length > 0 && (
                          <>
                            <ClearSelectedButton
                              count={selectedNotices.length}
                              onClear={() => setSelectedNotices([])}
                            />
                            :
                          </>
                        )}
                      </div>
                      {selectedNotices && selectedNotices.length === 0 && (
                        <EditColumnsButton
                          selectedColumns={tableColumns}
                          onNewColumnSettingChange={(columns) =>
                            setTableColumns(columns.map((c) => c.field) as ColumnSettingFieldEnum[])
                          }
                          allAvailableColumns={
                            hasFrameworks
                              ? [...ALL_NOTICE_COLUMNS, { title: "Framework", field: "framework" }]
                              : ALL_NOTICE_COLUMNS
                          }
                          dataType={EventDataTypes.record}
                        />
                      )}
                      <ExportNoticesButton filters={filters} selectedNotices={selectedNotices} />
                    </div>
                  )}
                </div>
                {filterCount > 0 ? (
                  <>
                    <div className={css.viewFilters}>
                      <QuickFilterBar
                        filters={filters}
                        onClearFilter={(key) =>
                          setFilters({
                            ...filters,
                            [key]: Array.isArray(filters[key]) ? [] : undefined,
                          })
                        }
                        onFilterClick={(key) => {
                          setIsFilterDrawerOpen(true);
                          setActiveGroups([findKeybyValue(key, groupedKeys) || ""]);
                        }}
                      />
                      {/* hide clear filters button if only one filter is applied and it is text search */}
                      {filterCount === 1 && filters.text !== "" ? null : (
                        <Button
                          type="link"
                          onClick={() => {
                            clearViewAndFilters();
                          }}
                          icon={<CloseCircleOutlined />}
                        >
                          Clear filters
                        </Button>
                      )}
                    </div>

                    {useOpenSearch ? (
                      <NewNoticeTable
                        filters={filters}
                        columnSettings={tableColumns.map((c) => c.field)}
                        onSortChange={(sort) => setFilters({ ...filters, sort })}
                        selectedRows={selectedNotices}
                        onSelectedRowsChange={(rows) => setSelectedNotices(rows)}
                        emptyText={<EmptyNotices setIsFilterDrawerOpen={setIsFilterDrawerOpen} />}
                      />
                    ) : (
                      <NoticeTable
                        filters={filters}
                        columnSettings={tableColumns.map((c) => c.field)}
                        onSortChange={(sort) => setFilters({ ...filters, sort })}
                        selectedRows={selectedNotices}
                        onSelectedRowsChange={(rows) => setSelectedNotices(rows)}
                        emptyText={<EmptyNotices setIsFilterDrawerOpen={setIsFilterDrawerOpen} />}
                      />
                    )}
                  </>
                ) : (
                  <div className={css.emptyState}>
                    <img src={MAGNIFYING_GLASS} alt="Magnifying glass" />
                    <h2>Select a view or some filters to get started</h2>
                    <p>
                      Notices matching your search will appear here. Learn more <br /> about{" "}
                      <a
                        href="https://help.stotles.com/filtering-and-saving-views-in-your-notice-feed"
                        target="_blank"
                      >
                        searching notices.
                      </a>
                    </p>
                  </div>
                )}
              </>
            )}
          </DetailsContent>
        )}
      </>
      {/* This is for the awards preview */}
      {paywallFeature === "AWARDS" && (
        <DetailsContent className={css.content}>
          <NoticeTable
            filters={filters}
            columnSettings={tableColumns.map((c) => c.field)}
            onSortChange={(sort) => setFilters({ ...filters, sort })}
            isPreview
          />
        </DetailsContent>
      )}

      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onClose={() => setIsFilterDrawerOpen(false)}
            onChange={debouncedSetFilters}
            filters={filters}
            showTitle={isCollapseEnabled}
            activeGroups={activeGroups}
            onActiveGroupsChange={setActiveGroups}
          />
        )}
      </FilterDrawer>
      {messageContext}
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(MyFeedPage, {
    pageName: "My Feed",
  }),
);
