import * as React from "react";
import { useForm } from "react-hook-form";
import { hot } from "react-hot-loader/root";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { App, Button, Collapse, Empty, Pagination, Tag, Tooltip } from "antd5";
import classnames from "classnames";
import equal from "fast-deep-equal";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { v4 as uuidV4 } from "uuid";

import ActionButton from "components/actions/ActionButton";
import { TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import { Input } from "components/form_components/Inputs";
import CentredSpinner from "lib/core_components/CentredSpinner";
import RecordStage from "lib/core_components/RecordStage";
import { RecordDto } from "lib/generated/app-api";
import { graphql } from "lib/generated/app-service-gql";
import { NoticeSignalsResponse, RuleResultsResponse } from "lib/generated/data-svc";
import { useGraphQlMutation, useGraphQlQuery } from "lib/hooks/api/useGraphQlClient";
import { useRecordSearch } from "lib/hooks/api/useRecordSearch";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "lib/hooks/api/utils";
import { usePreventNavigation } from "lib/hooks/usePreventNavigation";
import { useOpenApi } from "lib/openApiContext";
import { useRecordViewer } from "lib/providers/RecordViewer";
import { TenderStage } from "lib/types/models";
import { assert } from "lib/utils";

import css from "./CallOffIdentifierRuleEditor.module.scss";

const useRuleResults = (keywords: string[][]) => {
  const openApi = useOpenApi();
  return useQuery(
    ["call_off_identifier_rules", keywords],
    async () => {
      const resp = await openApi.previewCallOffs({
        body: { keywords },
      });
      return resp as RuleResultsResponse;
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, enabled: !!keywords.length },
  );
};

const query = graphql(`
  query describeFrameworkConfig($frameworkId: String!) {
    framework(frameworkId: $frameworkId) {
      id
      title
      callOff {
        count
      }
      serviceProvider {
        id
        name
      }
      callOffIdentificationRule {
        id
        frameworkId
        config {
          keywords
        }
      }
    }
  }
`);

const useFrameworkData = (frameworkId: string) => {
  const { data, ...rest } = useGraphQlQuery(
    ["framework_config", frameworkId],
    query,
    [{ frameworkId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );

  return { data: data?.framework, ...rest };
};

const mutation = graphql(`
  mutation persistCallOffConfig($args: PersistCallOffConfigRequest!) {
    persistCallOffConfig(PersistCallOffConfigRequest: $args) {
      id
      frameworkId
      config {
        keywords
      }
    }
  }
`);

const useUpdateConfig = (frameworkId: string | undefined) => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  return useGraphQlMutation(mutation, {
    onSuccess: () => {
      void queryClient.invalidateQueries(["framework_config", frameworkId]);
      void message.success("Success");
    },
    onError: () => {
      void message.error("An error occurred");
    },
  });
};

const signalFilterFactory = (keywords: string[][]) => {
  return keywords.map((ands) => ({
    id: ands.join(" AND "),
    filters: {
      all: ands.map((kw) => ({
        all: [
          {
            keywords: [`"${kw}"`],
          },
        ],
      })),
    },
  }));
};

/**
 * returns `data` as a map of procurement stage id to its match information
 */
const useNoticeSignals = (procurementStageIds: string[] | undefined, keywords: string[][]) => {
  const openApi = useOpenApi();
  return useQuery(
    ["signals", procurementStageIds, keywords],
    async () => {
      assert(procurementStageIds);

      const resp = await openApi.previewCallOffsNoticeSignals({
        body: {
          id: uuidV4(),
          languages: ["en"],
          ids: procurementStageIds,
          signals: signalFilterFactory(keywords),
        },
      });
      return resp
        ? Object.fromEntries((resp as NoticeSignalsResponse).results.map((r) => [r.id, r]))
        : resp;
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      enabled: !!procurementStageIds?.length && !!keywords.length,
    },
  );
};

/**
 * returns `data` as a map of procurement stage id to its match information
 */
const useDocumentSignals = (documentIds: string[] | undefined, keywords: string[][]) => {
  const openApi = useOpenApi();
  return useQuery(
    ["signals", documentIds, keywords],
    async () => {
      assert(documentIds);

      const resp = await openApi.previewCallOffsDocumentSignals({
        body: {
          id: uuidV4(),
          languages: ["en"],
          ids: documentIds,
          signals: signalFilterFactory(keywords),
        },
      });
      return resp
        ? Object.fromEntries((resp as NoticeSignalsResponse).results.map((r) => [r.id, r]))
        : resp;
    },
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      enabled: !!documentIds?.length && !!keywords.length,
    },
  );
};

function CallOffIdentifierRuleEditor({ framework_id }: { framework_id: string }) {
  const { data: frameworkData } = useFrameworkData(framework_id);
  const [keywords, setKeywords] = React.useState<string[][]>([]);
  const [resultsPage, setResultsPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(20);

  // useDeepCompareEffect throws if any of the dependencies are *not* an object, ie. if
  // frameworkData?.logic is undefined... it says you should use React.useEffect instead
  // https://github.com/kentcdodds/use-deep-compare-effect/issues/63#issuecomment-1372190117
  useDeepCompareEffectNoCheck(() => {
    const conf = frameworkData?.callOffIdentificationRule?.config;
    if (conf) {
      setKeywords(conf.keywords);
    }
  }, [frameworkData?.callOffIdentificationRule?.config]);

  const { data: response, isFetching: ruleResultsLoading } = useRuleResults(keywords);

  React.useEffect(() => {
    if (frameworkData?.title) {
      document.title = frameworkData.title;
    }
  }, [frameworkData]);

  const { mutate: save } = useUpdateConfig(frameworkData?.id);

  const { control, handleSubmit, formState, clearErrors, resetField, getValues } = useForm<{
    keyword: string;
  }>({
    defaultValues: { keyword: "" },
  });
  const unsavedChanges =
    getValues("keyword").length > 0 ||
    (!!frameworkData?.callOffIdentificationRule &&
      !equal(frameworkData.callOffIdentificationRule.config.keywords, keywords));

  usePreventNavigation(unsavedChanges, "You have unsaved changes - are you sure you want to exit?");

  const addKw = ({ keyword }: { keyword: string }) => {
    setKeywords((oldKw) => [...oldKw, keyword.split(" AND ")]);
    setResultsPage(1);
    resetField("keyword");
  };

  const procurementProcessIdsToFetch = response?.all.results.map((r) => r.procurementProcessId);

  const { data: notices, isLoading: noticesLoading } = useRecordSearch(
    {
      procurementProcessId: procurementProcessIdsToFetch,
      limit: pageSize,
      offset: (resultsPage - 1) * pageSize,
    },
    { enabled: !!procurementProcessIdsToFetch?.length },
  );

  const dedupedNotices = notices ? dedupeNoticesByRecordFamilyId(notices.results) : undefined;

  const otherMatchedFrameworkIdByProcurementProcessId = Object.fromEntries(
    response?.existingFrameworkMatches.map((m) => [m.procurementProcessId, m.frameworkId]) || [],
  );
  const matchedDocumentsByProcurementProcessId = Object.fromEntries(
    response?.documents.results.map(({ procurementProcessId, matchedElements }) => [
      procurementProcessId,
      matchedElements,
    ]) || [],
  );
  const { data: noticeSignalsByProcurementStageId } = useNoticeSignals(
    notices?.results.map((r) => r.procurementStage.id),
    keywords,
  );

  const { data: documentSignalsByDocumentId } = useDocumentSignals(
    response?.documents.results.flatMap((d) => d.matchedElements.map((e) => e.elementId)),
    keywords,
  );

  return (
    <div className={css.layout}>
      <div className={css.inputPanel}>
        <div className={css.header}>
          <h1>{frameworkData?.title || <CentredSpinner />}</h1>
          {frameworkData && (
            <ActionButton
              onAction={async () =>
                save({
                  args: {
                    frameworkId: frameworkData.id,
                    config: { keywords },
                    id: frameworkData.callOffIdentificationRule?.id || uuidV4(),
                  },
                })
              }
              disabled={keywords.length === 0}
              type="primary"
              danger
            >
              Save & apply
            </ActionButton>
          )}
        </div>
        <h3>Keywords</h3>
        {keywords.map((and, i, all) => (
          <div key={i}>
            <Tag key={i} color="blue">
              {and.map((kw, j) => (
                <React.Fragment key={j}>
                  <span>{kw}</span>
                  {j !== and.length - 1 && <i> AND </i>}
                </React.Fragment>
              ))}{" "}
              <CloseOutlined
                title="remove"
                onClick={() => setKeywords((oldKw) => oldKw.filter((_, j) => i !== j))}
              />
            </Tag>
            {i !== all.length - 1 && <p className={css.or}>OR</p>}
          </div>
        ))}
        <form onSubmit={handleSubmit(addKw)} className={css.form}>
          <Input
            name="keyword"
            label="Add a keyword"
            placeholder="Minimum length 4 chars, must be unique"
            control={control}
            rules={{
              required: { value: true, message: "Enter a new value" },
              validate: (keyword) => {
                if (keywords.find((ands) => ands.length === 1 && ands[0] === keyword)) {
                  return `"${keyword}" has already been added`;
                }

                return true;
              },
              minLength: { value: 4, message: "Must be 4 characters or longer" },
              onBlur: () => {
                if (getValues("keyword").length === 0) {
                  clearErrors("keyword");
                }
              },
            }}
          />
          <Button disabled={!formState.isValid} onClick={handleSubmit(addKw)}>
            Add +
          </Button>
        </form>
      </div>
      <div className={css.resultsPanel}>
        <h2>Results</h2>
        <Collapse
          ghost
          items={[
            {
              key: 1,
              label: `${response?.all.results.length || 0} results`,
              children: (
                <div>
                  <p>
                    {response?.notices.results.length || 0} procurement processes found from notices
                    search index
                  </p>
                  <p>
                    {response?.documents.results.length || 0} procurement processes found from
                    documents search index
                  </p>
                  <p>{dedupedNotices?.length || 0} procurement processes from records API</p>
                  <p>
                    {response?.existingFrameworkMatches.filter(
                      (efm) => efm.frameworkId !== framework_id,
                    ).length || 0}{" "}
                    procurement processes already matched as call-offs on other frameworks
                  </p>
                </div>
              ),
            },
          ]}
        ></Collapse>
        <ul>
          {ruleResultsLoading || noticesLoading || !dedupedNotices ? (
            response && response.all.results.length === 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <CentredSpinner />
            )
          ) : (
            dedupedNotices.map((r) => {
              const otherMatchedFrameworkId =
                otherMatchedFrameworkIdByProcurementProcessId[r.procurementStage.recordFamilyId];
              const matchedDocuments =
                matchedDocumentsByProcurementProcessId[r.procurementStage.recordFamilyId];
              const titleAndDescriptionMatchedKeywords =
                noticeSignalsByProcurementStageId?.[r.procurementStage.id]?.matchedSignalIds;
              const documentMatchedKeywords = matchedDocuments?.flatMap(
                (d) => documentSignalsByDocumentId?.[d.elementId]?.matchedSignalIds || [],
              );

              return (
                <RecordCard
                  key={r.guid}
                  record={r}
                  otherMatchedFrameworkId={
                    otherMatchedFrameworkId === framework_id ? undefined : otherMatchedFrameworkId
                  }
                  titleAndDescriptionMatchedKeywords={titleAndDescriptionMatchedKeywords}
                  documentMatchedKeywords={documentMatchedKeywords}
                />
              );
            })
          )}
        </ul>
        <Pagination
          total={notices?.pagingInfo.totalResults || 0}
          pageSize={pageSize}
          current={resultsPage}
          onChange={setResultsPage}
          onShowSizeChange={setPageSize}
        />
      </div>
    </div>
  );
}

const STAGE_ORDERING: Record<TenderStage, number> = {
  [TenderStage.PRE_TENDER]: 1,
  [TenderStage.STALE_PRE_TENDER]: 1,
  [TenderStage.CLOSED]: 2,
  [TenderStage.OPEN]: 2,
  [TenderStage.AWARDED]: 3,
};

const latestNotice = (a: RecordDto, b: RecordDto): RecordDto => {
  const aRank = STAGE_ORDERING[a.stage as TenderStage];
  const bRank = STAGE_ORDERING[b.stage as TenderStage];

  if (aRank && bRank) {
    return aRank >= bRank ? a : b;
  } else if (aRank) {
    return a;
  } else if (bRank) {
    return b;
  } else {
    return a;
  }
};

function dedupeNoticesByRecordFamilyId(notices: RecordDto[]): RecordDto[] {
  const newNotices: Record<string, RecordDto> = {};

  for (const notice of notices) {
    const famId = notice.procurementStage.recordFamilyId;
    const nn = newNotices[famId];
    if (nn) {
      newNotices[famId] = latestNotice(nn, notice);
    } else {
      newNotices[famId] = notice;
    }
  }

  return Object.values(newNotices);
}

function RecordCard({
  record,
  otherMatchedFrameworkId,
  titleAndDescriptionMatchedKeywords,
  documentMatchedKeywords,
}: {
  record: RecordDto;
  otherMatchedFrameworkId?: string;
  titleAndDescriptionMatchedKeywords: string[] | undefined;
  documentMatchedKeywords: string[] | undefined;
}): JSX.Element {
  const recordViewer = useRecordViewer();

  const matchingKeywords = React.useMemo(() => {
    const keywords: Record<string, string[]> = {};

    if (titleAndDescriptionMatchedKeywords) {
      for (const kw of titleAndDescriptionMatchedKeywords) {
        keywords[kw] = ["Title or description"];
      }
    }

    if (documentMatchedKeywords) {
      for (const kw of documentMatchedKeywords) {
        if (keywords[kw]) {
          keywords[kw].push("Documents");
        } else {
          keywords[kw] = ["Documents"];
        }
      }
    }

    return Object.entries(keywords);
  }, [titleAndDescriptionMatchedKeywords, documentMatchedKeywords]);

  return (
    <div
      className={classnames({
        [css.otherMatchedFrameworkId]: !!otherMatchedFrameworkId,
        [css.recordCard]: true,
      })}
      key={record.guid}
      onClick={() =>
        recordViewer.viewRecord(record.guid, {
          "Context source": "Call off identifier rules editor",
        })
      }
    >
      <div className={css.matchReasonContainer}>
        <p className={css.publishDate}>Published {record.publishDate}</p>
        {!!matchingKeywords?.length && (
          <div>
            <ul className={css.matchList}>
              {matchingKeywords.map(([kw, sources]) => (
                <li className={classnames(css.matchItem, css.keywordMatch)} key={kw}>
                  <Tooltip title={`Occurs in: ${sources.join(" + ")}`}>{kw}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={css.infoContainer}>
        <div>
          <span>
            <RecordStage stage={record.stage as TenderStage} className={css.stageLabel} />
            {record.stage === TenderStage.AWARDED ? (
              <p>
                Award date: {record.awardDate || "unknown"} | Expiry date:{" "}
                {record.expiryDate || "unknown"}
              </p>
            ) : (
              <p>Close date: {record.closeDate}</p>
            )}
          </span>
        </div>
        <div className={css.title}>{record.name}</div>
        <p className={css.buyerName}>{record.buyer?.name}</p>
        {otherMatchedFrameworkId && (
          <p style={{ color: "red" }}>
            Already matches framework{" "}
            <TextLink
              to={`/frameworks/${otherMatchedFrameworkId}/call-offs`}
              targetType="new-tab"
              onClick={(e) => e.stopPropagation()}
            >
              {otherMatchedFrameworkId}
            </TextLink>
          </p>
        )}
      </div>
    </div>
  );
}

export default hot(
  withAppLayout(CallOffIdentifierRuleEditor, {
    pageName: "Framework identifier configs editor",
  }),
);
