import * as React from "react";
import { useLocation } from "wouter";

import PaywallModal, { PaywallModalProps } from "components/paywall/PaywallModal";
import { convertDataTypeToFeatureType, FeatureType } from "components/paywall/paywallUtils";
import NoticePreviewContainer from "components/record_details/NoticePreview";
import RecordPreviewContainer from "components/record_details/RecordPreview";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useDialogManager } from "lib/providers/DialogManager";
import { ProHelperDataTypes, useProHelper } from "lib/providers/ProHelper";
import { useRecordViewer } from "lib/providers/RecordViewer";
import { useSubscription } from "lib/providers/Subscription";
import { EventData } from "lib/tracking";
import { RecordDetails } from "lib/types/models";

/* This hook checks the users access against the required data type and either shows the record slideout
if they have access or shows the paywall. 
*/
export function useRestrictedRecordRowClick(
  getRequiredDataTypeForRecord: (r: RecordDetails) => ProHelperDataTypes | undefined,
) {
  const { checkSubscription } = useProHelper();
  const { viewRecord } = useRecordViewer();

  const dialogManager = useDialogManager();

  const openPaywallModal = React.useCallback(
    (featureType: FeatureType) => {
      void dialogManager.openDialog(PaywallModal, {
        featureType: featureType,
        contextSource: "In-row",
      } as Omit<PaywallModalProps, "isOpen" | "onClose">);
    },
    [dialogManager],
  );

  // Create an onRow click event where if the user doesn't have access, we show the restricted access slideout
  // If they do have access, we show the record slideout
  const onRecordClick = React.useCallback(
    (r: RecordDetails) => ({
      onClick: () => {
        const requiredDataType = getRequiredDataTypeForRecord(r);
        if (
          requiredDataType &&
          !checkSubscription(requiredDataType, { "Context source": "In-row" })
        ) {
          return;

          // For guest users, show paywalls on award data
        } else if (window.guestUser && r.stage === "AWARDED") {
          const featureType = convertDataTypeToFeatureType(requiredDataType);
          openPaywallModal(featureType);
        } else {
          viewRecord(r.guid, { "Context source": "Row click" });
        }
      },
    }),
    [checkSubscription, getRequiredDataTypeForRecord, openPaywallModal, viewRecord],
  );

  return onRecordClick;
}

export function useRestricedViewNotice(
  contextSource = "Row click",
  requiredDataType?: ProHelperDataTypes,
  trackingData?: EventData,
) {
  const useNoticeDetailsWithGraphQL = FeatureToggles.isEnabled(Feature.NOTICE_DETAILS_GRAPHQL);

  const { checkSubscription } = useProHelper();
  const { viewRecord: openRecord } = useRecordViewer({
    renderContent: (recordGuid) =>
      useNoticeDetailsWithGraphQL ? (
        <NoticePreviewContainer guid={recordGuid} eventData={trackingData} />
      ) : (
        <RecordPreviewContainer recordGuid={recordGuid} eventData={trackingData} />
      ),
  });

  const viewRecord = (recordGuid: string) => {
    if (
      requiredDataType &&
      !checkSubscription(requiredDataType, { "Context source": contextSource })
    ) {
      return;
    }

    openRecord(recordGuid, { "Context source": contextSource });
  };

  return { viewRecord };
}

type ClickthroughProps<ItemType> = {
  requiredDataType: ProHelperDataTypes;
  getDestination: (i: ItemType) => string;
};

/* This hook checks the users access against the required data type and either takes the user to a destination 
if have access or shows the paywall. 
*/
export function useRestrictedClickthrough<ItemType>({
  requiredDataType,
  getDestination,
}: ClickthroughProps<ItemType>) {
  const subscription = useSubscription();
  const dialogManager = useDialogManager();

  const openPaywallModal = React.useCallback(
    (featureType: FeatureType) => {
      void dialogManager.openDialog(PaywallModal, {
        featureType: featureType,
      } as Omit<PaywallModalProps, "isOpen" | "onClose">);
    },
    [dialogManager],
  );

  // Guest users can view information superfically, but not click through
  const canViewAdvancedData = subscription.hasDataTypes(requiredDataType) && !window.guestUser;
  const { checkSubscription } = useProHelper();

  const clickThrough = React.useMemo(() => {
    if (canViewAdvancedData) {
      return createHandleTableRowClickthrough(getDestination);
    } else if (window.guestUser) {
      const featureType = convertDataTypeToFeatureType(requiredDataType);
      return () => ({ onClick: () => openPaywallModal(featureType) });
    } else {
      return () => ({
        onClick: () => checkSubscription(requiredDataType, { "Context source": "In-row" }),
      });
    }
  }, [canViewAdvancedData, getDestination, requiredDataType, openPaywallModal, checkSubscription]);

  return clickThrough;
}

// Same as useRestrictedClickthrough but for a wouter link
export function useRestrictedLink(
  requiredDataType: ProHelperDataTypes,
): (destination: string) => void {
  const [, navigate] = useLocation();
  const { checkSubscription } = useProHelper();

  if (checkSubscription(requiredDataType, { "Context source": "In-row" })) {
    return (destination) => navigate(destination);
  }

  // because of how checkSubscription works, this code will never be reached
  return () => checkSubscription(requiredDataType, { "Context source": "In-row" });
}

export function useRestrictedGuestAccess(trackingData?: EventData) {
  const dialogManager = useDialogManager();
  return () => {
    void dialogManager.openDialog(PaywallModal, {
      featureType: "GUEST_ACCESS_UPGRADE",
      contextSource: "Guest pass modal",
      trackingData,
    });
  };
}
