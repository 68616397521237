import * as React from "react";
import { hot } from "react-hot-loader/root";
import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { withAppLayout } from "components/app_layout/AppLayout";
import { FilterableFrameworks } from "components/framework/FilterableFrameworks";
import { FrameworkColumns } from "components/framework/FrameworkTable";
import { graphql } from "lib/generated/app-service-gql";
import {
  SearchFrameworksQuery,
  SearchFrameworksRequest,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "lib/hooks/api/useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "lib/hooks/api/utils";
import { ColumnSetting } from "lib/types/models";

import css from "./CallOffIdentifierRulesPage.module.scss";

const query = graphql(`
  query searchFrameworkConfigs($request: SearchFrameworksRequest!) {
    frameworks(SearchFrameworksRequest: $request) {
      results {
        id
        title
        callOff {
          count
        }
        serviceProvider {
          id
          name
        }
        callOffIdentificationRule {
          id
          frameworkId
          config {
            keywords
          }
        }
      }
      totalResults
    }
  }
`);

export type SearchFrameworksResponse = SearchFrameworksQuery["frameworks"];

const useData = (
  searchFrameworksRequest: SearchFrameworksRequest,
  _includeSignals: boolean,
  options?: UseQueryOptions<SearchFrameworksQuery, unknown, SearchFrameworksQuery, QueryKey>,
) => {
  const { data, ...rest } = useGraphQlQuery(
    ["framework_configs", searchFrameworksRequest],
    query,
    [{ request: searchFrameworksRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.frameworks, ...rest };
};

const COLUMNS: ColumnSetting<FrameworkColumns>[] = [
  {
    title: "Framework title",
    field: "title",
  },
  {
    title: "Identification rule",
    field: "callOffIdentifierRule",
  },
  {
    title: "Number of call-offs",
    field: "callOffs",
  },
];

function CallOffIdentiferRulesPage() {
  return (
    <div className={css.page}>
      <h1>Framework identifier configurations</h1>
      <FilterableFrameworks
        defaultColumns={COLUMNS}
        allAvailableColumns={COLUMNS}
        frameworkProvider={useData}
      />
    </div>
  );
}

export default hot(
  withAppLayout(CallOffIdentiferRulesPage, { pageName: "Call off identifier rules page" }),
);
