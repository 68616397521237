import React from "react";

import { useFrameworksExport } from "../../lib/hooks/api/frameworks/useFrameworksExport";
import { useSearchFrameworks } from "../../lib/hooks/api/frameworks/useSearchFrameworks";
import { ExportButtonGraphql } from "../actions/ExportButtonGraphql";
import { convertFrameworkFiltersToRequest, DEFAULT_PAGINATION, FrameworkFilters } from "./utils";

export default function ExportFrameworksButton({
  filters,
  selectedFrameworks,
}: {
  filters: FrameworkFilters;
  selectedFrameworks?: string[];
}) {
  const { isLoading, data: frameworkSearch } = useSearchFrameworks(
    convertFrameworkFiltersToRequest(filters, DEFAULT_PAGINATION),
    false,
  );

  const frameworkExportMutation = useFrameworksExport();

  const totalResults: number =
    selectedFrameworks && selectedFrameworks.length > 0
      ? selectedFrameworks.length
      : frameworkSearch?.totalResults || 0;

  const frameworkSearchRequest = convertFrameworkFiltersToRequest(filters, {
    current: 1,
    pageSize: totalResults + 1,
  });

  return (
    <ExportButtonGraphql
      isLoadingTotalResults={isLoading}
      totalResults={totalResults}
      exportMutation={frameworkExportMutation}
      exportVariables={{
        filters: frameworkSearchRequest,
        selectedFrameworks: { frameworkIds: selectedFrameworks },
      }}
    />
  );
}
