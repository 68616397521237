import React from "react";
import { Control } from "react-hook-form";
import { Divider } from "antd5";

import { NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import RedactedWrapper from "../../../lib/core_components/RedactedWrapper";
import { Radio } from "../../form_components/Inputs";
import { MentionsAwardsFilter } from "../../form_components/MentionsAwardsFilter";
import { NewSupplierSelect } from "../../form_components/NewSupplierSelect";
import { SupplierSelect } from "../../form_components/SupplierSelect";
import { FilterOptions } from "../FilterForm";
import { NoticeFilters } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface SuppliersFilterContentProps {
  control: Control<NoticeFilters, unknown>;
  data: NoticeFilters;
  options?: FilterOptions;
}

function SuppliersFilterContent({ control, data, options }: SuppliersFilterContentProps) {
  const enableNewSupplierFilter =
    useVariableValue(NEW_SUPPLIER_FILTER, false) && window.currentUser?.use_supplier_name === false;

  return (
    <div className={css.filterSection} aria-label="Supplier filters">
      {enableNewSupplierFilter ? (
        <>
          {!options?.hiddenFilters?.includes("supplierGuids") && (
            <NewSupplierSelect name="supplierGuids" label="Supplier Guids" control={control} />
          )}

          {!options?.hiddenFilters?.includes("supplierMentionType") && (
            <RedactedWrapper
              redactContent={<MentionsAwardsFilter control={control} />}
              requiredDataType="SUPPLIERS"
              contextSource="Supplier filter"
            >
              {data.supplierGuids && data.supplierGuids.length > 0 && (
                <MentionsAwardsFilter control={control} />
              )}
            </RedactedWrapper>
          )}
        </>
      ) : (
        <SupplierSelect
          name="suppliers"
          label=""
          control={control}
          mode="multiple"
          placeholder="Search suppliers..."
          allowClear
        />
      )}
      {!options?.hiddenFilters?.includes("supplierSme") && (
        <>
          <Divider style={{ margin: "8px 0" }} />
          <RedactedWrapper
            redactContent={
              <Radio
                label="SME Supplier?"
                name={"supplierSme"}
                control={control}
                disabled
                options={[
                  { label: "Show all suppliers", value: "" },
                  { label: "Show only SMEs", value: true },
                  { label: "Hide SMEs", value: false },
                ]}
                optionType="default"
                direction="vertical"
              />
            }
            requiredDataType="SUPPLIERS"
            contextSource="Supplier filter"
          >
            <Radio
              label="SME Supplier?"
              name={"supplierSme"}
              control={control}
              options={[
                { label: "Show all suppliers", value: "" },
                { label: "Show only SMEs", value: true },
                { label: "Hide SMEs", value: false },
              ]}
              optionType="default"
              direction="vertical"
            />
          </RedactedWrapper>
        </>
      )}
    </div>
  );
}

export default SuppliersFilterContent;
